import Vue from 'vue'
import axios from 'axios'
import store from '../store/index'
import md5 from 'md5'
import router from '../router/index'

const service = axios.create({
    // baseURL: 'http://159.138.236.92', // app接口
    baseURL: process.env.API_ROOT,
    timeout: 5000000 // 请求超时时间
})

service.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
        config.withCredentials = true  // Enable sending cookies with requests
    }
    return config
}, error => {

    Promise.reject(error)
})
service.interceptors.response.use(
    response => {
        if (response.data.code == 4001) {
            // 清除用户信息
            store.commit('setUserInfo', {})
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            
            // 添加英文弹窗提示
            Vue.prototype.$message({
                message: 'Login expired, please login again',
                type: 'warning',
                duration: 2000,
                onClose: () => {
                    router.push('/login')
                }
            })
            return
        }
        return response.data
    },
    error => {
        if (error.response && error.response.status === 401) {
            // 清除用户信息
            store.commit('setUserInfo', {})
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            
            // 添加英文弹窗提示
            Vue.prototype.$message({
                message: 'Login expired, please login again',
                type: 'warning',
                duration: 2000,
                onClose: () => {
                    router.push('/login')
                }
            })
            return
        }
        return Promise.reject(error)
    }
)
export {
    service as axios
}
