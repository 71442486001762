export default {
  login: {
    title: '登录',
    username: '用户名',
    password: '密码',
    remember: '记住我',
    submit: '登录',
    forgot: '忘记密码？',
    logout: '退出登录',
    loginViaEmail: '邮箱登录',
    loginViaDiscord: 'Discord登录',
    emailPlaceholder: '请输入邮箱',
    passwordPlaceholder: '请输入密码',
    resetPassword: '重置密码',
    sendCode: '发送验证码'
  },
  nav: {
    home: '首页',
    product: '产品',
    dashboard: '仪表盘',
    support: '支持',
    faq: '常见问题',
    contact: '联系我们',
    userList: '用户列表',
    logout: '退出登录'
  },
  dashboard: {
    showMenu: '显示菜单',
    hideMenu: '隐藏菜单',
    proxies: '代理',
    servers: '服务器',
    settings: '设置',
    profile: '个人资料',
    backToTop: '返回顶部'
  },
  common: {
    search: '搜索',
    add: '添加',
    edit: '编辑',
    delete: '删除',
    cancel: '取消',
    confirm: '确认',
    save: '保存',
    success: '成功',
    error: '错误',
    loading: '加载中...',
    noData: '暂无数据',
    operation: '操作',
    status: '状态',
    actions: '操作'
  }
}
