import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './en'
import zhLocale from './zh'
import jaLocale from './ja'
import ElementLocale from 'element-ui/lib/locale'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'

Vue.use(VueI18n)

// 合并语言包
const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  ja: {
    ...jaLocale,
    ...elementJaLocale
  }
}

// 调试：打印当前语言设置
const storedLang = localStorage.getItem('language')
console.log('Stored language in localStorage:', storedLang)

// 获取默认语言
const currentLang = storedLang || 'en'
console.log('Using language:', currentLang)

// 创建 VueI18n 实例
const i18n = new VueI18n({
  locale: currentLang,
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true
})

// 配置 Element UI 的语言
ElementLocale.i18n((key, value) => i18n.t(key, value))

// 设置 Element UI 的初始语言
const elementLocales = {
  en: elementEnLocale,
  zh: elementZhLocale,
  ja: elementJaLocale
}

try {
  ElementLocale.use(elementLocales[currentLang])
  console.log('Element UI locale set to:', currentLang)
} catch (error) {
  console.error('Error setting Element UI locale:', error)
}

// 添加一个全局方法用于切换语言
Vue.prototype.$setLanguage = function(lang) {
  if (['en', 'zh', 'ja'].includes(lang)) {
    localStorage.setItem('language', lang)
    i18n.locale = lang
    ElementLocale.use(elementLocales[lang])
    return true
  }
  return false
}

export default i18n
