<template>
  <div class="header" :class="scrollTop > 0 ? 'on' : ''" style="z-index: 999;">
      <div class="wrap clearfix">
          <a class="logo" @click="toUrl('/')"><img src="@/assets/images/logo.png" alt=""/></a>
          <div class="rts clearfix">
              <div class="naver">
                  <ul class="clearfix">
                      <li :class="index == 0 ? 'on' : ''">
                          <h2><a @click="toUrl('/')">{{ $t('nav.home') }}</a></h2>
                      </li>
                      <li :class="index == 1 ? 'on' : ''">
                          <h2><a @click="toUrl('/product')">{{ $t('nav.product') }}</a></h2>
                      </li>
                      <li :class="index == 2 ? 'on' : ''">
                          <h2><a @click="toUrl('/dashboard')">{{ $t('nav.dashboard') }}</a></h2>
                      </li>
                      <li :class="index == 3 ? 'on' : ''" @click="toUrl('/support')" @mouseenter="onHover2(1)"
                          @mouseleave="onHover2(0)">
                          <h2>
                              <a>{{ $t('nav.support') }}<i></i></a>
                          </h2>
                          <div class="nlis" :class="isHover2 == 1 ? 'hasHover' : 'noHover'" v-if="showHover2">
                              <dl>
                                  <dd><a>{{ $t('nav.faq') }}</a></dd>
                                  <dd><a>{{ $t('nav.contact') }}</a></dd>
                              </dl>
                          </div>
                      </li>
                  </ul>
              </div>

              <!-- <LanguageSwitcher v-if="index !== -1" /> -->

              <div class="login_box" v-if="!token">
                  <div class="login" @click="toUrl('/login')">
                      <span>{{ $t('login.title') }}</span>
                  </div>
              </div>
              <div v-else class="login_box" @mouseleave="showLoginOut = false">
                  <div class="login-pic">
                      <img src="../assets/images/people.png" @mouseover="showLoginOut = true" alt="">
                  </div>
                  <div class="login-out" v-if="showLoginOut">
                      <template v-if="!$store.state.userInfo.is_superuser">
                          <div class="login-t">
                              <span>{{ userInfo.username }}</span>
                              <i class="el-icon-user user-icon" title="User"></i>
                          </div>
                          <div class="line"></div>
                          <div class="login_menu_item" @click="toUrl('/dashboard/index/proxies')">Proxies</div>
                          <div class="line"></div>
                          <div class="login_menu_item" @click="toUrl('/dashboard/index/setting')">Settings</div>
                          <div class="line"></div>
                          <div class="login_out_box" @click="submitLoginOut">Logout</div>
                      </template>
                      
                      <template v-else>
                          <div class="login-t">
                              <span>{{ userInfo.username }}</span>
                              <i class="el-icon-s-tools admin-icon" title="Administrator"></i>
                          </div>
                          <div class="line"></div>
                          <div class="login_menu_item" @click="toUrl('/dashboard/index/orders')">Orders</div>
                          <div class="line"></div>
                          <div class="login_menu_item" @click="toUrl('/dashboard/index/productManager')">Products</div>
                          <div class="line"></div>
                          <div class="login_menu_item" @click="toUrl('/dashboard/index/servers')">Servers</div>
                          <div class="line"></div>
                          <div class="login_out_box" @click="submitLoginOut">Logout</div>
                      </template>
                  </div>
              </div>

              <!-- 在 rts div 内添加移动端菜单按钮 -->
              <div class="mobile-menu" v-show="isMobile" @click="toggleMobileMenu">
                  <i class="el-icon-menu"></i>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import {productList} from "@/api/index"
import {loginOut} from "@/api/login"
// import LanguageSwitcher from '@/components/LanguageSwitcher.vue'

export default {
  name: "header-com",
  components: {
      // LanguageSwitcher
  },
  props: {
      index: {
          type: Number,
          required: true,
          default: 0,
      },
      scrollTop: {
          type: Number,
          required: false,
          default: 0,
      },
  },
  data() {
      return {
          userInfo: {},
          isHover: 0,
          showHover: false,
          isHover2: false,
          showHover2: false,
          labelListData: [],
          token: localStorage.getItem('token') || null,
          showLoginOut: false,
          isMobile: false,
          mobileMenuVisible: false,
      }
  },
  watch: {
      "$store.state.userInfo": {
          handler(newVal) {
              this.userInfo = newVal
              this.token = newVal && Object.keys(newVal).length > 0
          },
          immediate: true
      }
  },
  mounted() {
      if (this.$store.state.userInfo && Object.keys(this.$store.state.userInfo).length) {
          this.userInfo = this.$store.state.userInfo
          this.token = true
      }

      // 添加响应式检测
      this.checkMobile();
      window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.checkMobile);
  },
  computed: {
      isAdmin() {
          return this.$store.state.userInfo && Object.keys(this.$store.state.userInfo).length > 0 && this.$store.state.userInfo.is_superuser
      }
  },
  methods: {
      submitLoginOut() {
          loginOut(
              (res) => {
                  this.$store.commit('setUserInfo', {})
                  localStorage.removeItem('token')
                  localStorage.removeItem('userInfo')
                  this.token = null
                  this.userInfo = {}
                  this.showLoginOut = false
                  this.$router.push('/login')
              },
              (err) => {
                  this.$message.error(err.msg || 'Logout failed')
              }
          )
      },
      toUrl(url) {
          if (this.$store.state.userInfo && this.isAdmin && (url.includes('/proxies') || url.includes('/settings'))) {
              url = '/dashboard/index/orders'
          }
          this.$router.replace({path: url})
      },
      onHover(type) {
          var that = this
          this.isHover = type
          if (type == 1) {
              this.showHover = true
          } else {
              setTimeout(function () {
                  that.showHover = false
              }, 500)
          }
      },
      onHover2(type) {
          var that = this
          that.isHover2 = type
          if (type == 1) {
              that.showHover2 = true
          } else {
              setTimeout(function () {
                  that.showHover2 = false
              }, 500)
          }
      },
      // 获取标签列表
      getLabelList() {
          var that = this
          return
          productList(
              {},
              (res) => {
                  this.labelListData = res.result
              },
              (err) => {
                  console.log(err)
              }
          )
      },
      // 跳转product
      toProduct(index) {
          this.$router.replace({path: "/product", query: {index: index}})
      },
      LogoutFunc() {
          localStorage.setItem("userInfo", JSON.stringify({}))
          this.$store.commit("setUserInfo", {})
          localStorage.setItem("token", "")
          window.location.replace(
              "https://discord.com/api/oauth2/authorize?client_id=983333457579094056&redirect_uri=http%3A%2F%2Fdashboard.mentostelecom.com&response_type=code&scope=identify"
          )
      },
      checkMobile() {
          this.isMobile = window.innerWidth <= 768;
      },
      toggleMobileMenu() {
          this.mobileMenuVisible = !this.mobileMenuVisible;
      }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login_box {
position: relative;

.login-pic {
  margin-top: 8px;
  width: auto;
  min-width: 30px;
  
  img {
    width: 30px;
    height: 30px;
    display: block;
  }
}

.login-out {
  position: absolute;
  right: 0;
  top: 100%;
  width: 140px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  border-top-left-radius: 0px;
  background: #f34087;
  z-index: 1000;

  .login-t {
    text-align: center;
    line-height: 38px;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      &:first-child {
        color: #fff;
      }
    }
  }

  .line {
    border: 1px solid #fff;
  }

  .login_out_box {
    text-align: center;
    line-height: 38px;
    cursor: pointer;
    transition: background-color 0.3s;
    
    &:hover {
      background-color: darken(#f34087, 5%);
    }
  }

  .login_menu_item {
    text-align: center;
    line-height: 38px;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    &:hover {
      background-color: darken(#f34087, 5%);
    }

    .is-admin & {
      font-weight: 500;
    }
  }

  .line {
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    margin: 0;
  }
}

.login {
  padding: 0 20px;
  height: 46px;
  line-height: 46px;
  
  span {
    font-size: 16px;
    white-space: nowrap;
  }
}
}

.language-switch {
  float: right;
  margin-left: 15px;
  height: 70px;
  display: flex;
  align-items: center;
}
.language-switch :deep(.el-dropdown-link) {
  color: #333;
  cursor: pointer;
  font-size: 14px;
}
.language-switch :deep(.el-dropdown-link:hover) {
  color: #409EFF;
}
// .header .rts .login span {
//         width: 40px;
//         line-height: 50px;
//         font-size: 30px;
//         font-weight: 600;
//         color: #f38cb5;
//         font-weight: bold;
//         border-radius: 10px;
//         // background: #f34087;
//         text-align: center;
//         display:block;
//         white-space:nowrap;
//         overflow:hidden;
//         text-overflow:ellipsis;
//         cursor: pointer;
//         display: block;
//         transition: all 0.3s;
//     }
//     .header .rts .login_box {
//         float: right;
//         cursor: pointer;
//         position: relative;
//     }
//     .header .rts .login {
//         .login-list {

//     }
//     }

// 修改现有的 iPad 响应式样式
@media screen and (max-width: 1024px) {
.header {
  .wrap {
    padding: 0 20px;
    
    .logo {
      img {
        height: 36px; // 稍微调小logo
      }
    }
    
    .rts {
      .naver {
        ul {
          li {
            margin-left: 12px; // 减小间距
            
            h2 {
              font-size: 14px;
              
              a {
                padding: 0 8px; // 减小内边距
              }
            }
          }
        }
      }
      
      .login_box {
        margin-left: 12px;
        
        .login {
          padding: 0 15px;
          height: 40px;
          line-height: 40px;
          
          span {
            font-size: 14px;
          }
        }
        
        .login-pic {
          margin: 5px 0;
          
          img {
            width: 30px;
            height: 30px;
          }
        }
        
        .login-out {
          width: 120px;
          font-size: 14px;
          
          .login-t,
          .login_out_box {
            line-height: 34px;
            padding: 0 8px;
          }
        }
      }
    }
  }
}
}

// 优化移动端样式
@media screen and (max-width: 768px) {
.header {
  .wrap {
    .rts {
      .naver {
        display: none;
      }
      
      .login_box {
        margin-left: auto;
        margin-right: 10px;
        
        .login-out {
          right: 0;
        }
      }
    }
  }
}
}

.login_box {
.login-out {
  .login-t {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 0 10px;
    
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px; // 限制用户名最大宽度
    }
    
    .admin-icon {
      font-size: 16px;
      color: #ffd04b; // 金色图标表示管理员
      cursor: help;
      transition: all 0.3s;
      
      &:hover {
        transform: rotate(180deg);
      }
    }
    
    .user-icon {
      font-size: 14px;
      color: #fff;
      opacity: 0.8;
      cursor: help;
    }
  }
}
}

// iPad 响应式样式
@media screen and (max-width: 1024px) {
.login_box {
  .login-out {
    .login-t {
      .admin-icon,
      .user-icon {
        font-size: 14px;
      }
      
      span {
        max-width: 80px; // iPad上减小用户名最大宽度
      }
    }
  }
}
}
</style>
