import Vue from 'vue'
import Vuex from 'vuex'
import {userPointers} from "@/api/dashboard/giftUser"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),//用户信息
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
            if (Object.keys(userInfo).length > 0) {
                localStorage.setItem('userInfo', JSON.stringify(userInfo))
                // Refresh token in cookie if it exists in userInfo
                if (userInfo.token) {
                    localStorage.setItem('token', userInfo.token)
                }
            } else {
                localStorage.removeItem('userInfo')
                localStorage.removeItem('token')
                // Clear any existing cookies
                document.cookie.split(";").forEach(function(c) { 
                    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                });
            }
        },
        setSocialUrl(state, socialUrl) {
            state.socialUrl = socialUrl
            state.expiredTime = new Date().getTime() + 1000 * 60 * 60 * 24 * 7
        }
    },
    actions: {
        refreshUserInfo({commit}) {
            userPointers(
                (res) => {
                    commit('setUserInfo', res.data)
                },
                (err) => {
                    console.log(err)
                }
            )
        }
    },
    modules: {}
})
