export default {
  login: {
    title: 'Login',
    username: 'Username',
    password: 'Password',
    remember: 'Remember me',
    submit: 'Login',
    forgot: 'Forgot Password?',
    logout: 'Logout',
    loginViaEmail: 'Login via Email',
    loginViaDiscord: 'Login via Discord',
    emailPlaceholder: 'Enter your email',
    passwordPlaceholder: 'Enter your password',
    resetPassword: 'Reset Password',
    sendCode: 'Send Code'
  },
  nav: {
    home: 'Home',
    product: 'Product',
    dashboard: 'Dashboard',
    support: 'Support',
    faq: 'FAQ',
    contact: 'Contact',
    userList: 'User List',
    logout: 'Logout'
  },
  dashboard: {
    showMenu: 'Show Menu',
    hideMenu: 'Hide Menu',
    proxies: 'Proxies',
    servers: 'Servers',
    settings: 'Settings',
    profile: 'Profile',
    backToTop: ''
  },
  common: {
    search: 'Search',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    success: 'Success',
    error: 'Error',
    loading: 'Loading...',
    noData: 'No Data',
    operation: 'Operation',
    status: 'Status',
    actions: 'Actions'
  }
}
