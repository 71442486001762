import {axios} from '@/utils/request'

// 用户列表
export const getUserList = (msg, sucFn, errorFn) => {
    axios({
        method: 'get',
        url: '/api/users/u/',
        params: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const banUser = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: `/api/users/u/${msg.id}/baned_user/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const unbanUser = (msg, sucFn, errorFn) => {
    axios({
        method: 'post',
        url: `/api/users/u/${msg.id}/unbaned_user/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const deleteUserApi = (msg, sucFn, errorFn) => {
    axios({
        method: 'DELETE',
        url: `/api/users/u/${msg.id}/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const resetPasswordApi = (msg, sucFn, errorFn) => {
    axios({
        method: 'POST',
        url: `/api/users/u/${msg.id}/change_password/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}


export const updataUserApi = (msg, sucFn, errorFn) => {
    axios({
        method: 'PUT',
        url: `/api/users/u/${msg.id}/`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}
export const userChangePassword = (msg, sucFn, errorFn) => {
    axios({
        method: 'POST',
        url: `/api/users/change_password`,
        data: msg
    })
        .then(function (res) {
            if (res.code === 2000) {
                typeof sucFn === 'function' && sucFn(res)
            } else {
                typeof errorFn === 'function' && errorFn(res)
            }
        })
        .catch(function (code, error) {
            typeof errorFn === 'function' && errorFn(code, error)
        })

}

// 切换到指定用户身份
export function switchToUser(userId, sucFn, errorFn) {
    axios({
        url: '/api/users/admin/switch-user',
        method: 'post',
        data: {
            user_id: userId
        }
    })
    .then(function (res) {
        if (res.code === 2000) {
            typeof sucFn === 'function' && sucFn(res)
        } else {
            typeof errorFn === 'function' && errorFn(res)
        }
    })
    .catch(function (code, error) {
        typeof errorFn === 'function' && errorFn(code, error)
    })
}