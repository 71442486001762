<template>
    <div class="settings-container">
        <!-- 用户信息卡片 -->
        <el-card class="box-card user-profile-card" shadow="hover">
            <div class="user-profile">
                <div class="user-avatar">
                    <el-avatar :size="80" icon="el-icon-user-solid"></el-avatar>
                </div>
                <div class="user-info">
                    <h1 class="username">{{ $store.state.userInfo.username }}</h1>
                    <div class="user-stats">
                        <div class="stat-item">
                            <i class="el-icon-money"></i>
                            <span>Points: {{ $store.state.userInfo.points || 0 }}</span>
                        </div>
                        <div class="stat-item" v-if="$store.state.userInfo.discord_name">
                            <i class="el-icon-connection"></i>
                            <span>Discord: {{ $store.state.userInfo.discord_name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>

        <el-card class="box-card" id="a">
            <div class="cardHeader">
                <h2>Account Information</h2>
            </div>
            <div class="card-content">
                <el-form>
                    <el-form-item label="Username" class="formItem">
                        <el-tag type="danger">{{ $store.state.userInfo.username }}</el-tag>
                    </el-form-item>
                    <el-form-item label="Email" class="formItem">
                        <el-tag type="danger">{{ $store.state.userInfo.email }}</el-tag>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>

        <el-card class="box-card" id="b">
            <div class="cardHeader">
                <h2>Change Password</h2>
                <el-button class="cardbutton" @click="showEditPassword = !showEditPassword">
                    {{ showEditPassword ? "Cancel" : "Change" }}
                </el-button>
            </div>
            <div v-show="showEditPassword" class="card-content">
                <el-form :model="editPasswordForm" label-width="180px" ref="editPasswordRef" :rules="rules">
                    <el-form-item label="Old Password" class="formItem" prop="old_password">
                        <el-input show-password v-model="editPasswordForm.old_password"
                                  placeholder="Enter your current password"></el-input>
                    </el-form-item>
                    <el-form-item label="New Password" class="formItem" prop="new_password">
                        <el-input show-password v-model="editPasswordForm.new_password"
                                  placeholder="Enter your new password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" @click="handleEditPassword">Update Password</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>

        <el-card class="box-card" id="c">
            <div class="cardHeader">
                <h2>{{ discord_id ? 'Discord Account' : 'Connect Discord' }}</h2>
                <el-button v-if="discord_id" class="cardbutton discord-btn" disabled>
                    <i class="el-icon-check"></i>
                    {{ $store.state.userInfo.discord_name }}
                </el-button>
                <el-button v-else class="cardbutton" @click="submitThirdLogin">
                    <i class="el-icon-connection"></i>
                    Connect Discord
                </el-button>
            </div>
        </el-card>

        <el-card class="box-card" id="d">
            <div class="cardHeader">
                <h2>Billing Management</h2>
                <el-button class="cardbutton" @click="$router.push({ name: 'Proxies' })">
                    <i class="el-icon-wallet"></i>
                    Manage Billing
                </el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import {userChangePassword} from "@/api/dashboard/userList"
import {
    thirdLogin
} from "@/api/login"
import {userPointers} from "@/api/dashboard/giftUser";

export default {
    data() {
        return {
            discord_id: null,
            showEditPassword: false,
            editPasswordForm: {
                old_password: "",
                new_password: ""
            },
            rules: {
                old_password: [
                    {required: true, message: 'Please Enter Old Password', trigger: 'blur'},
                ],
                new_password: [
                    {required: true, message: 'Please Enter New Password', trigger: 'blur'},
                ]
            },
            thirdLoginUrl: null,
        }
    },
    created() {
        if (window.location.href.indexOf('discord-bind-success=1') > -1) {
            this.$message.success(`Discord bind successfully, Name: ${this.$store.state.userInfo.discord_name}`);
        }
        if (window.location.href.indexOf('discord-bind-error=1') > -1) {
            this.$message.warning(`Discord bind failed,the discord account has been bound to other account`);
        }

    },
    mounted() {
        this.getThirdLoginUrl()
        this.refreshUserInfo()
        this.discord_id = this.$store.state.userInfo.discord_id
    },
    methods: {
        refreshUserInfo() {
            userPointers(
                (res) => {
                    this.$store.commit("setUserInfo", res.data)
                },
                (err) => {
                    // this.$message.warning(err.msg);
                }
            );
        },
        submitThirdLogin() {
            // this.loading = true;
            if (!this.$store.state.userInfo || this.$store.state.userInfo.discord_id) {
                return
            }
            if (!this.thirdLoginUrl.length) {
                this.$message.warning('Unable to obtain third-party login path!')
                return
            }
            const a = document.createElement("a")
            a.href = this.thirdLoginUrl
            a.target = "_top"
            document.body.appendChild(a)
            a.click()
            a.remove()
        },
        getThirdLoginUrl() {
            const param = {mode: "bind"}
            thirdLogin(
                (res) => {
                    // ;
                    this.thirdLoginUrl = res.data.discord_auth_url
                },
                (err) => {
                    // this.$message.warning(err.msg);
                },
                param
            )

        },
        handleEditPassword() {
            this.$refs["editPasswordRef"].validate((valid) => {
                if (valid) {
                    userChangePassword(this.editPasswordForm, res => {

                    }, res => {

                    })
                } else {
                    return false
                }
            })
        }
    },

}
</script>

<style lang="scss" scoped>
.settings-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.user-profile-card {
    margin-bottom: 24px;
    background: linear-gradient(135deg, #FF4D4F 0%, #ff7875 100%);
    
    :deep(.el-card__body) {
        padding: 0;
    }
}

.user-profile {
    padding: 32px;
    display: flex;
    align-items: center;
    color: white;

    .user-avatar {
        margin-right: 24px;
        
        :deep(.el-avatar) {
            border: 4px solid rgba(255, 255, 255, 0.3);
        }
    }

    .user-info {
        flex: 1;

        .username {
            margin: 0 0 16px 0;
            font-size: 24px;
            font-weight: 600;
        }

        .user-stats {
            display: flex;
            gap: 24px;

            .stat-item {
                display: flex;
                align-items: center;
                
                i {
                    margin-right: 8px;
                    font-size: 18px;
                }
                
                span {
                    font-size: 14px;
                }
            }
        }
    }
}

.box-card {
    margin-bottom: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    
    :deep(.el-card__body) {
        padding: 0;
    }

    .cardHeader {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;

        h2 {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            color: #303133;
        }

        .cardbutton {
            min-width: 120px;
            height: 36px;
            border-radius: 4px;
            background: #FF4D4F;
            color: white;
            border: none;
            font-size: 14px;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            &:hover {
                background: #ff7875;
                box-shadow: 0 2px 8px rgba(255, 77, 79, 0.3);
            }

            &.discord-btn {
                background: #5865F2;
                
                &:hover {
                    background: #7289DA;
                }
            }

            i {
                font-size: 16px;
            }
        }
    }

    .card-content {
        padding: 20px;
    }
}

.formItem {
    margin-bottom: 16px;
    
    :deep(.el-form-item__label) {
        font-weight: 500;
        color: #606266;
    }
    
    :deep(.el-input__inner) {
        border-radius: 4px;
    }
    
    :deep(.el-tag) {
        font-size: 14px;
        padding: 6px 12px;
        border-radius: 4px;
    }
}

:deep(.el-button--danger) {
    background: #FF4D4F;
    border-color: #FF4D4F;
    
    &:hover {
        background: #ff7875;
        border-color: #ff7875;
    }
}
</style>