<template>
  <div>
    <div class="layout">
      <header-com :index="2" :scrollTop="scrollTop"></header-com>
      <div class="fproxies">
        <div class="wrap">
          <div class="fproxielt" :class="{ collapsed: isCollapsed }">
            <div class="menu-container">
              <el-menu
                class="el-menu-vertical-demo"
                :collapse="isCollapsed"
                background-color="transparent"
              >
                <!-- 用户 -->
                <el-menu
                  class="el-menu-vertical-demo"
                  text-color="#404040"
                  unique-opened
                  v-if="!$store.state.userInfo.is_superuser"
                >
                  <!--  -->
                  <el-menu-item
                    index="collapse"
                    class="collapse-menu-item hover"
                    @click="toggleCollapse"
                  >
                    <i
                      :class="[
                        'el-icon-d-arrow-' + (isCollapsed ? 'right' : 'left'),
                      ]"
                    ></i>
                    <span slot="title">{{
                      isCollapsed ? $t('dashboard.showMenu') : $t('dashboard.hideMenu')
                    }}</span>
                  </el-menu-item>
                  <el-menu-item
                    index="0"
                    class="hover"
                    @click="$router.push({ name: 'home' })"
                  >
                    <i class="el-icon-s-home"></i>
                    <span slot="title">{{ $t('nav.home') }}</span>
                  </el-menu-item>
                  <el-submenu index="1">
                    <template slot="title">
                      <div
                        class="hover"
                        @click="$router.push({ 
                          path: `/dashboard/index/proxies/${$route.params.id || ''}`
                        })"
                      >
                        <i class="el-icon-connection"></i>
                        <span>{{ $t('dashboard.proxies') }}</span>
                      </div>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item
                        class="setFont"
                        :index="`1-0`"
                        @click="
                          $router.push({
                            path: `/dashboard/index/proxies`
                          })
                        "
                      >
                        <i class="el-icon-time"></i>
                        Recent Orders
                        <el-badge
                          :value="orderCount || 0"
                          class="order-badge"
                        />
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        :index="`1-${index + 1}`"
                        v-for="(item, index) in itemList"
                        :key="item.shopify_collection_id"
                        @click="
                          $router.push({
                            path: `/dashboard/index/proxies/${item.id}`,
                          })
                        "
                      >
                        {{ item.collection_name }}
                        <el-badge
                          :value="proxyOrderCounts[item.id] || 0"
                          class="order-badge"
                        />
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-submenu index="2">
                    <template slot="title">
                      <div class="hover">
                        <i class="el-icon-medal"></i>
                        <span>Rewards</span>
                      </div>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item
                        class="setFont"
                        index="5-1"
                        @click="$router.push({ name: 'promocodeUser' })"
                        >Promo Code
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        index="5-2"
                        @click="$router.push({ name: 'giftcardUser' })"
                        >Gift Card
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-menu-item
                    index="3"
                    class="hover"
                    @click="$router.push({ name: 'invite' })"
                  >
                    <i class="el-icon-share"></i>
                    <span slot="title">Invite</span>
                  </el-menu-item>
                  <el-menu-item
                    index="4"
                    class="hover"
                    @click="$router.push({ name: 'userSettings' })"
                  >
                    <i class="el-icon-setting"></i>
                    <span>{{ $t('dashboard.settings') }}</span>
                  </el-menu-item>
                  <el-menu-item
                    index="5"
                    class="hover"
                    @click="$router.push({ name: 'support' })"
                  >
                    <i class="el-icon-question"></i>
                    <span slot="title">Help</span>
                  </el-menu-item>
                </el-menu>
                <!-- 管理员 -->
                <el-menu
                  class="el-menu-vertical-demo"
                  text-color="#404040"
                  unique-opened
                  v-else
                >
                  <!--  -->
                  <el-menu-item
                    index="collapse"
                    class="collapse-menu-item hover"
                    @click="toggleCollapse"
                  >
                    <i
                      :class="[
                        'el-icon-d-arrow-' + (isCollapsed ? 'right' : 'left'),
                      ]"
                    ></i>
                    <span slot="title">{{
                      isCollapsed ? $t('dashboard.showMenu') : $t('dashboard.hideMenu')
                    }}</span>
                  </el-menu-item>
                  <el-menu-item
                    index="0"
                    class="hover"
                    @click="$router.push({ name: 'home' })"
                  >
                    <i class="el-icon-s-home"></i>
                    <span slot="title">{{ $t('nav.home') }}</span>
                  </el-menu-item>
                  <el-menu-item
                    index="1"
                    class="hover"
                    @click="$router.push({ name: 'userList' })"
                  >
                    <i class="el-icon-user-solid"></i>
                    <span>User Manage</span>
                  </el-menu-item>
                  <el-menu-item
                    index="2"
                    class="hover"
                    @click="$router.push({ name: 'Orders' })"
                  >
                    <i class="el-icon-s-order"></i>
                    <span slot="title">Orders</span>
                  </el-menu-item>
                  <el-submenu index="3">
                    <template slot="title">
                      <div class="hover">
                        <i class="el-icon-s-goods"></i>
                        <span slot="title">Products</span>
                      </div>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item
                        class="setFont"
                        index="3-1"
                        @click="$router.push({ name: 'productManager' })"
                        >Product Manage
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        index="3-2"
                        @click="$router.push({ name: 'productType' })"
                        >Product Type
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        index="3-3"
                        @click="$router.push({ name: 'productTag' })"
                        >Product Tag
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <!-- <el-menu-item index="4" class="hover">
                    <img src="@/assets/images/fn9.png" alt="">
                    <img src="@/assets/images/fn9-1.png" alt="" style="display: none;">
                    <span slot="title">Promo Code</span>
                  </el-menu-item> -->
                  <el-submenu index="4">
                    <template slot="title">
                      <div class="hover">
                        <i class="el-icon-medal"></i>
                        <span>Rewards</span>
                      </div>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item
                        class="setFont"
                        index="4-1"
                        @click="$router.push({ name: 'promocode' })"
                        >Promo Code
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        index="4-2"
                        @click="$router.push({ name: 'giftcard' })"
                        >Gift Card
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        index="4-3"
                        @click="$router.push({ name: 'leveldiscount' })"
                        >Level Discount
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-submenu index="5">
                    <template slot="title">
                      <div class="hover">
                        <i class="el-icon-s-platform"></i>
                        <span>{{ $t('dashboard.servers') }}</span>
                      </div>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item
                        class="setFont"
                        index="5-1"
                        @click="$router.push({ name: 'servers' })"
                        >Server Manage
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        index="5-2"
                        @click="$router.push({ name: 'servergroup' })"
                        >Server Group Manage
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        index="5-3"
                        @click="$router.push({ name: 'AclManager' })"
                        >Acl Manage
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        index="5-4"
                        @click="$router.push({ name: 'AclGroupManager' })"
                        >Acl Group Manage
                      </el-menu-item>
                      <el-menu-item
                        class="setFont"
                        index="5-5"
                        @click="$router.push({ name: 'CIDRManage' })"
                        >Cidr Manage
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-menu-item
                    index="6"
                    class="hover"
                    @click="$router.push({ name: 'tickets' })"
                  >
                    <i class="el-icon-message"></i>
                    <span slot="title">Tickets</span>
                  </el-menu-item>
                  <el-menu-item
                    index="7"
                    class="hover"
                    @click="$router.push({ name: 'WebsiteSettings' })"
                  >
                    <i class="el-icon-s-tools"></i>
                    <span slot="title">Web Settings</span>
                  </el-menu-item>
                  <el-menu-item
                    index="8"
                    class="hover"
                    @click="$router.push({ name: 'QA' })"
                  >
                    <i class="el-icon-question"></i>
                    <span slot="title">Q&A</span>
                  </el-menu-item>
                  <el-menu-item
                    index="11"
                    class="hover"
                    @click="$router.push({ name: 'tasks' })"
                  >
                    <i class="el-icon-s-operation"></i>
                    <span slot="title">Tasks</span>
                  </el-menu-item>
                </el-menu>
              </el-menu>
            </div>
          </div>
          <div
            class="fproxiert"
            :class="{ expanded: isCollapsed, fullscreen: isFullscreen }"
          >
            <div class="content-header">
              <h2 class="content-title">
                <i :class="getCurrentIcon"></i>
                <span>{{ getCurrentTitle }}</span>
              </h2>
            </div>
            <button class="expand-button" @click="toggleFullscreen">
              <i :class="isFullscreen ? 'el-icon-close' : 'el-icon-rank'"></i>
            </button>
            <el-card class="box-card">
              <div class="card-content" ref="cardBody">
                <!-- 卡片内容区域 -->
                <div class="card-scroll-container">
                  <router-view></router-view>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <!-- 返回顶部按钮 -->
        <div class="back-to-top" v-show="showBackTop" @click="scrollToTop">
          <i class="el-icon-top"></i>
          <span>{{ $t('dashboard.backToTop') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import headerCom from "@/components/header";
import { getProxiesItem } from "@/api/dashboard/orders";
import { getOrdersList } from '@/api/dashboard/orders'

export default {
  name: "proxies",
  components: {
    headerCom,
  },
  data() {
    return {
      tabIndex: 0, //0proxies1rewards2settings
      scrollTop: 0,
      itemList: [],
      orderCount: 0,
      proxyOrderCounts: {},  // Store order counts for each proxy type
      showBackTop: false, // 控制返回顶部按钮显示
      isCollapsed: false,
      isAtStart: true,
      isAtEnd: false,
      scrolling: false,
      isFullscreen: false,
      currentTitle: "",
      showButtons: false,
    };
  },
  updated() {},
  mounted() {
    this.getOrderCount()
    this.handleGetProxiesItem();
    const contentContainer = document.querySelector(".fproxiert");
    if (contentContainer) {
      contentContainer.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeDestroy() {
    const contentContainer = document.querySelector(".fproxiert");
    if (contentContainer) {
      contentContainer.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    setIndex(index) {
      this.tabIndex = index;
    },
    toWebUrl(name) {
      let routeData = this.$router.resolve({
        name: name,
      });
      window.open(routeData.href, "_blank");
    },
    handleScroll() {
      const contentContainer = document.querySelector(".fproxiert");
      if (contentContainer) {
        const scrollTop = contentContainer.scrollTop;
        this.scrollTop = scrollTop;
        this.showBackTop = scrollTop > 300 || window.pageYOffset > 300;
      }
    },

    handleGetProxiesItem() {
      getProxiesItem((res) => {
        this.itemList = res.data.data;
        this.getProxyOrderCounts(); // Get order counts after loading proxy items
      });
    },
    getOrderCount() {
      getOrdersList(
        { limit: 1, offset: 0 },
        (res) => {
          this.orderCount = res.data.total || 0
        },
        () => {
          this.orderCount = 0
        }
      )
    },
    getProxyOrderCounts() {
      this.itemList.forEach(item => {
        this.$set(this.proxyOrderCounts, item.id, 0)
        
        getOrdersList(
          { limit: 1, offset: 0, product_type: item.id },
          (res) => {
            this.$set(this.proxyOrderCounts, item.id, res.data.total || 0)
          },
          () => {
            this.$set(this.proxyOrderCounts, item.id, 0)
          }
        )
      })
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    scrollToTop() {
      // 获取右侧内容容器
      const contentContainer = document.querySelector(".fproxiert");
      if (contentContainer) {
        contentContainer.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
      
      // 同时滚动整个窗口
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    // 检查是否需要显示滚动按钮
    checkScrollable() {
      const wrapper = this.$refs.contentWrapper;
      if (wrapper) {
        this.showButtons = wrapper.scrollWidth > wrapper.clientWidth;
        this.updateScrollState();
      }
    },
    // 更新滚���状态
    updateScrollState() {
      const wrapper = this.$refs.contentWrapper;
      if (wrapper) {
        this.isAtStart = wrapper.scrollLeft <= 0;
        this.isAtEnd =
          wrapper.scrollLeft + wrapper.clientWidth >= wrapper.scrollWidth;
      }
    },
    // 滚动内容
    scrollContent(direction) {
      if (this.scrolling) return;

      const wrapper = this.$refs.contentWrapper;
      if (!wrapper) return;

      this.scrolling = true;
      const scrollAmount = wrapper.clientWidth * 0.5; // 滚动50%的视口宽度
      const targetScroll =
        direction === "left"
          ? wrapper.scrollLeft - scrollAmount
          : wrapper.scrollLeft + scrollAmount;

      wrapper.scrollTo({
        left: targetScroll,
        behavior: "smooth",
      });

      // 滚动完成后更新状态
      setTimeout(() => {
        this.scrolling = false;
        this.updateScrollState();
      }, 300);
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        // 进入全屏时添加键盘事件监听
        document.addEventListener("keydown", this.handleEscKey);
      } else {
        // 退出全屏时移除键盘事件监听
        document.removeEventListener("keydown", this.handleEscKey);
      }
    },
    handleEscKey(event) {
      if (event.key === "Escape" && this.isFullscreen) {
        this.isFullscreen = false;
        document.removeEventListener("keydown", this.handleEscKey);
      }
    },
  },
  computed: {
    getCurrentTitle() {
      const routeMap = {
        home: "Home",
        Proxies: "Proxies",
        rewards: "Rewards",
        invite: "Invite",
        userSettings: "Settings",
        support: "Help",
        userList: "User List",
        Orders: "Orders",
        promocode: "Promo Code",
        giftcard: "Gift Card",
        giftcardUser: "Gift Card",
        leveldiscount: "Level Discount",
        QA: "Q&A",
        servers: "Servers",
        servergroup: "Server Group",
        CIDRManage: "CIDR Manage",
        AclGroupManager: "ACL Group",
        productManager: "Product Manager",
        productType: "Product Type",
        productTag: "Product Tag",
        tasks: "Tasks",
      };
      return routeMap[this.$route.name] || "Dashboard";
    },
    getCurrentIcon() {
      const iconMap = {
        home: "el-icon-s-home",
        Proxies: "el-icon-connection",
        rewards: "el-icon-medal",
        invite: "el-icon-share",
        userSettings: "el-icon-setting",
        support: "el-icon-question",
        userList: "el-icon-user-solid",
        Orders: "el-icon-s-order",
        promocode: "el-icon-medal",
        giftcard: "el-icon-medal",
        giftcardUser: "el-icon-medal",
        leveldiscount: "el-icon-medal",
        QA: "el-icon-question",
        servers: "el-icon-s-platform",
        servergroup: "el-icon-s-platform",
        CIDRManage: "el-icon-s-platform",
        AclGroupManager: "el-icon-s-tools",
        productManager: "el-icon-s-goods",
        productType: "el-icon-s-goods",
        productTag: "el-icon-s-goods",
        tasks: "el-icon-s-operation",
      };
      return iconMap[this.$route.name] || "el-icon-s-home";
    },
  },
};
</script>

<style scoped>
.layout {
  width: 100%;
  min-height: 100vh;
  background: url(../../assets/images/fbj1.jpg) no-repeat center/cover;
  overflow-x: hidden;
}

.fproxies {
  display: flex;
  width: 100%;
  height: calc(100vh - 70px); /* 调整高度，与header保持一致 */
  overflow: hidden;
  padding-top: 20px; /* 添加顶部内边距 */
  box-sizing: border-box;
}

.wrap {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  gap: 20px; /* 添加左右间距 */
}

.fproxielt {
  width: 280px;
  height: calc(100% - 20px); /* 减去顶部padding */
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
  position: relative;
  backdrop-filter: blur(10px);
  border-radius: 12px; /* 添加圆角 */
  margin-left: 20px; /* 添加左边距 */
}

.fproxielt.collapsed {
  width: 64px;
}

.fproxiert {
  flex: 1;
  padding: 0 40px 20px 20px; /* 移除顶部padding */
  min-height: calc(100vh - 90px); /* 调整高度 */
  transition: all 0.3s ease;
  box-sizing: border-box;
  overflow-x: auto;
  position: relative;
  max-width: calc(100% - 300px); /* 调整宽度计算 */
}

.fproxiert.expanded {
  margin-left: 0;
  max-width: calc(100% - 84px); /* 调整折叠状态下的宽度计算 */
}

.fproxiert.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: none;
  margin: 0;
  z-index: 9999;
  background: #fff;
  padding: 20px;
  overflow: auto;
}

/* 卡片样式调整 */
.el-card.box-card {
  border: none;
  background: transparent;
  margin-top: 12px; /* 减小顶部边距 */
  border-radius: 12px;
  overflow: hidden;
}

/* 卡片内容区域调整 */
.card-content {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: -10px; /* 抵消el-card的默认内边距 */
}

.card-scroll-container {
  width: 100%;
  overflow-x: auto;
  padding: 0 20px 20px; /* 移除顶部内边距，保留左右和底部内边距 */
  box-sizing: border-box;
  /* 隐藏滚动条但保持可滚动 */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* 确保内容区域与header的间距合适 */
.content-header {
  margin: 0 0 12px 0; /* 减小底部边距 */
}

/* 展开/收起按钮 */
.expand-button {
  position: absolute;
  top: 12px; /* 调整按钮位置 */
  right: 40px; /* 调整右侧位置，与内容区域对齐 */
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 50%; /* 改为圆形 */
  background: rgba(243, 64, 135, 0.1); /* 更改为主题色半透明背景 */
  color: #f34087; /* 图标颜色改为主题色 */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  backdrop-filter: blur(4px); /* 添加毛玻璃效果 */
  box-shadow: 0 2px 8px rgba(243, 64, 135, 0.1); /* 添加主题色阴影 */
}

.expand-button:hover {
  background: rgba(243, 64, 135, 0.2);
  transform: scale(1.05);
}

.expand-button i {
  font-size: 20px;
}

/* 全屏状态下的按钮样式 */
.fproxiert.fullscreen .expand-button {
  top: 20px;
  right: 20px;
}

/* 替换图标 */
.expand-button i.el-icon-full-screen {
  font-size: 18px;
}

.expand-button i.el-icon-close {
  font-size: 20px;
}

/* iPad 响应式适配 */
@media screen and (max-width: 1024px) {
  .expand-button {
    top: 16px;
    right: 30px;
    width: 32px;
    height: 32px;
  }
  
  .expand-button i {
    font-size: 16px;
  }
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .expand-button {
    top: 12px;
    right: 20px;
    width: 30px;
    height: 30px;
  }
  
  .expand-button i {
    font-size: 14px;
  }
}

/* 折叠按钮样式 */
.collapse-menu-item {
  padding: 0 !important;
  height: 40px !important;
  line-height: 40px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.collapse-menu-item i {
  color: #409eff;
  font-size: 16px;
  transition: all 0.3s ease;
  margin-left: 20px;
}

.collapse-menu-item:hover i {
  transform: scale(1.1);
}

/* 菜单容器相对定位 */
.menu-container {
  position: relative;
  height: 100%;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.2); /* 相应调整菜单容器透明度 */
}

.el-menu {
  border: none !important;
  background-color: transparent !important;
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}

.el-menu-item,
.el-submenu__title {
  background-color: transparent !important;
}

.el-menu-item:hover,
.el-submenu__title:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

/* 菜单样式调整 */
.el-menu-vertical-demo {
  border: none;
  background-color: transparent;
}

.el-menu-vertical-demo span {
  font-size: 16px;
  display: inline-block;
  margin-left: 8px;
  transition: opacity 0.3s ease; /* 添加过渡效果 */
}

.collapsed .el-menu-vertical-demo span {
  opacity: 0; /* 收起时隐文字 */
}

.el-menu-item i,
.el-submenu__title i {
  font-size: 18px; /* 增加图标大小 */
  transition: all 0.3s ease;
}

/* 图标颜色 */
.el-icon-s-home {
  color: #409eff; /* 蓝色 */
}

.el-icon-connection {
  color: #67c23a; /* 绿色 */
}

.el-icon-medal {
  color: #e6a23c; /* 金色 */
}

.el-icon-share {
  color: #f56c6c; /* 红色 */
}

.el-icon-setting {
  color: #909399; /* 灰色 */
}

.el-icon-message {
  color: #409eff; /* 蓝色 */
}

.el-icon-s-tools {
  color: #67c23a; /* 绿色 */
}

.el-icon-question {
  color: #e6a23c; /* 金色 */
}

.el-icon-s-operation {
  color: #f56c6c; /* 红色 */
}

.el-icon-user-solid {
  color: #409eff; /* 蓝色 */
}

.el-icon-s-order {
  color: #67c23a; /* 绿色 */
}

.el-icon-s-goods {
  color: #e6a23c; /* 金色 */
}

.el-icon-s-platform {
  color: #f56c6c; /* 红色 */
}

/* 菜单悬停效果 */
.hover:hover i,
.el-menu-item:hover i,
.el-submenu__title:hover i {
  color: #f34087 !important; /* 统一的悬停颜色 */
  transform: scale(1.1);
  transition: all 0.3s ease;
}

/* 子菜单项悬停效果 */
.el-menu-item-group .el-menu-item {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.el-menu-item-group .el-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
  transform: translateX(5px);
  color: #f34087 !important;
}

.el-menu-item-group .el-menu-item:hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #f34087;
}

.el-menu-item-group .el-menu-item:hover i {
  transform: scale(1.1) rotate(10deg);
}

/* 返回顶部按钮 */
.back-to-top {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(64, 158, 255, 0.9);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 9999;
  box-shadow: 0 2px 12px rgba(64, 158, 255, 0.3);
}

.back-to-top:hover {
  background-color: #409eff;
  transform: scale(1.1);
}

.back-to-top i {
  font-size: 20px;
  color: white;
}

/* 应式调整 */
@media screen and (max-width: 1024px) {
  .back-to-top {
    right: 20px;
    bottom: 20px;
    width: 36px;
    height: 36px;
  }

  .back-to-top i {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .back-to-top {
    right: 15px;
    bottom: 15px;
    width: 32px;
    height: 32px;
  }
}

/* 标题样式优化 */
.content-header {
  display: flex;
  justify-content: space-between; /* 改为两端对齐 */
  align-items: center;
  padding: 0 24px; /* 添加水平内边距 */
  height: 60px;
  background: rgba(255, 255, 255, 0.8); /* 添加半透明背景 */
  backdrop-filter: blur(10px); /* 毛玻璃效果 */
  border-radius: 12px; /* 圆角 */
  margin: 0 0 20px 0;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05); /* 添加阴影 */
  transition: all 0.3s ease;
}

.content-header:hover {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.content-title {
  font-size: 18px;
  font-weight: 500;
  color: #303133;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px; /* 使用gap替代margin */
  cursor: pointer;
  transition: all 0.3s ease;
}

.content-title i {
  font-size: 22px;
  color: #f34087;
  transition: all 0.3s ease;
}

.content-title i:hover {
  transform: rotate(15deg) scale(1.1);
}

.content-title span {
  line-height: 1.4;
  position: relative;
  padding-bottom: 2px;
}

.content-title span::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #f34087;
  transition: width 0.3s ease;
}

.content-title:hover {
  color: #f34087;
}

.content-title:hover span::after {
  width: 100%;
}

/* 添加右侧操作区域 */
.content-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.content-actions .action-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  color: #606266;
  background: transparent;
  border: 1px solid #dcdfe6;
  cursor: pointer;
  transition: all 0.3s ease;
}

.content-actions .action-button:hover {
  color: #f34087;
  border-color: #f34087;
  background: rgba(243, 64, 135, 0.1);
}

.content-actions .action-button i {
  margin-right: 4px;
}

/* iPad 响应式适配 */
@media screen and (max-width: 1024px) {
  .content-header {
    padding: 0 16px;
    height: 56px;
  }
  
  .content-title {
    font-size: 16px;
  }
  
  .content-title i {
    font-size: 20px;
  }
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .content-header {
    padding: 0 12px;
    height: 50px;
  }
  
  .content-title {
    font-size: 15px;
  }
  
  .content-title i {
    font-size: 18px;
  }
  
  .content-actions .action-button {
    padding: 6px 12px;
    font-size: 13px;
  }
}

.order-badge {
  margin-left: 8px;
  :deep(.el-badge__content) {
    border: none;
    display: inline-block !important;
    background-color: #f34087;
    color: #fff;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    border-radius: 9px;
  }
}

/* 添加悬停效果 */
.el-menu-item:hover .order-badge :deep(.el-badge__content) {
  background-color: #ff5c9b;
  transform: scale(1.1);
  transition: all 0.3s;
}
</style>
