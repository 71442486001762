import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from './i18n'
import cookies from 'js-cookie'
import JsonViewer from 'vue-json-viewer'
import wow from 'wowjs'
import animate from 'animate.css'
import 'vue-json-viewer/style.css'
import 'wowjs/css/libs/animate.css'
import {axios} from '@/utils/request'
import '@/assets/styles/base/slicy.css'
import '@/assets/styles/modules/style.css'

// Set up Vue prototypes
Vue.prototype.$cookies = cookies
Vue.prototype.axios = axios
Vue.prototype.$wow = wow
Vue.prototype.Domain = 'http://admin.mentostelecom.com/mentos/download?id='

// Use plugins
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(JsonViewer)
Vue.use(animate)

Vue.config.productionTip = false

const whitelist = ["/", "/product", "/support", "/login", "/createAccount"]
router.beforeEach((to, from, next) => {
    if (whitelist.includes(to.path)) {
        next()
    } else {
        var url = window.location.hash;
        if (url.match(/\?refresh-user=1$/)) {
            store.dispatch('refreshUserInfo')
        }
        if (store.state.userInfo || url.indexOf("dashboard?") != -1) {
            next()
        } else {
            next({path: '/login'})
        }
    }

})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    if (localStorage.getItem("store")) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem("store"))))
    }
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state))
    })
  }
}).$mount('#app')
