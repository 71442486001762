<template>
    <div class="invite-container">
        <el-card class="tip-card">
            <div class="tip-content">
                <i class="el-icon-info tip-icon"></i>
                <p class="tip-text">You can earn points by inviting new users, and you can earn points when the invited users spend money.</p>
            </div>
        </el-card>
        
        <el-card class="invite-card" shadow="hover">
            <div class="invite-info" v-loading="cardLoading">
                <div class="invite-item">
                    <div class="invite-label">Invite URL</div>
                    <div class="invite-value">
                        <span class="value-text">{{ invite_url }}</span>
                        <el-button 
                            type="primary" 
                            class="copy-btn" 
                            id="btn" 
                            data-clipboard-action="copy" 
                            :data-clipboard-text="invite_url"
                            @click="copy">
                            <i class="el-icon-document-copy"></i>
                            Copy
                        </el-button>
                    </div>
                </div>
                <div class="invite-item">
                    <div class="invite-label">Invite Code</div>
                    <div class="invite-value">
                        <span class="value-text">{{ invite_code }}</span>
                        <el-button 
                            type="primary" 
                            class="copy-btn" 
                            id="btn" 
                            data-clipboard-action="copy" 
                            :data-clipboard-text="invite_code"
                            @click="copy">
                            <i class="el-icon-document-copy"></i>
                            Copy
                        </el-button>
                    </div>
                </div>
            </div>
            
            <div class="table-container">
                <el-table 
                    :data="tableData" 
                    style="width: 100%" 
                    header-cell-class-name="table-header"
                    v-loading="tableLoading">
                    <el-table-column prop="id" label="Number" width="100" align="center"></el-table-column>
                    <el-table-column prop="username" label="Username" align="center"></el-table-column>
                    <el-table-column prop="created_at" label="Registration Time" align="center" show-overflow-tooltip></el-table-column>
                </el-table>
                
                <el-pagination
                    background
                    layout="->,prev, pager, next"
                    :total="total"
                    class="pagination"
                    :current-page.sync="currentPage"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import {getInvite, getInviteData} from "@/api/invite"
import ClipboardJS from 'clipboard'

export default {
    data() {
        return {
            tableLoading: false,
            cardLoading: false,
            tableData: [],
            invite_url: "",
            invite_code: "",
            currentPage: 1,
            total: 0,
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        this.getInviteMethod()
        this.getTableData()
    },
    methods: {
        handleCurrentChange(val) {

            this.getTableData()
        },
        getInviteMethod() {
            this.cardLoading = true
            getInvite(
                (res) => {
                    this.invite_url = res.data.invite_url
                    this.invite_code = res.data.invite_code
                    this.cardLoading = false
                },
                (res) => {
                    this.cardLoading = false
                    this.$message.error(res.msg)
                })
        },
        copy(type) {

            const clipboard = new ClipboardJS('#btn')
            clipboard.on('success', (e) => {

                e.clearSelection()
                // 释放内存
                clipboard.destroy()
                this.$message.success("Successful replication")
            })
            // 复制失败
            clipboard.on('error', (e) => {
                clipboard.destroy()
                this.$message.error("Replication failure")
            })
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {limit: 10, offset}
            this.tableLoading = true
            getInviteData(param, (res) => {

                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            })
        },
    }
}
</script>

<style scoped lang="scss">
.invite-container {
    padding: 20px;
    
    .tip-card {
        margin-bottom: 24px;
        background: linear-gradient(135deg, #FF4D4F 0%, #ff7875 100%);
        border: none;
        
        :deep(.el-card__body) {
            padding: 20px;
        }
    }
    
    .tip-content {
        display: flex;
        align-items: center;
        color: white;
        
        .tip-icon {
            font-size: 24px;
            margin-right: 12px;
        }
        
        .tip-text {
            margin: 0;
            font-size: 16px;
            line-height: 1.5;
        }
    }
    
    .invite-card {
        border: none;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
        
        :deep(.el-card__body) {
            padding: 24px;
        }
    }
    
    .invite-info {
        margin-bottom: 32px;
    }
    
    .invite-item {
        background: #fff1f0;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 16px;
        
        &:last-child {
            margin-bottom: 0;
        }
        
        .invite-label {
            font-size: 14px;
            color: #606266;
            margin-bottom: 8px;
        }
        
        .invite-value {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: white;
            border-radius: 6px;
            padding: 12px;
            border: 1px solid #ffccc7;
            
            .value-text {
                font-size: 14px;
                color: #303133;
                word-break: break-all;
                margin-right: 12px;
            }
            
            .copy-btn {
                padding: 8px 16px;
                border-radius: 4px;
                background-color: #FF4D4F !important;
                border-color: #FF4D4F !important;
                
                &:hover {
                    background-color: #ff7875 !important;
                    border-color: #ff7875 !important;
                }
                
                i {
                    margin-right: 4px;
                }
            }
        }
    }
    
    .table-container {
        :deep(.table-header) {
            background-color: #fff1f0 !important;
            color: #606266;
            font-weight: 500;
        }
        
        :deep(.el-table) {
            border-radius: 8px;
            overflow: hidden;
            
            th {
                background-color: #fff1f0;
                border-bottom: 1px solid #ffccc7;
            }
            
            td {
                padding: 16px 0;
            }

            tr:hover > td {
                background-color: #fff1f0 !important;
            }
        }
    }
    
    .pagination {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        
        :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
            background-color: #FF4D4F !important;
            border-color: #FF4D4F !important;
        }

        :deep(.el-pagination.is-background .el-pager li:not(.disabled):hover) {
            color: #FF4D4F;
        }

        :deep(.btn-prev:hover, .btn-next:hover) {
            color: #FF4D4F;
        }
    }
}
</style>