import { axios } from '@/utils/request'

// 所有API方法定义
export function getOrdersList(params, success, fail) {
  return axios({
    url: '/api/orders/order_list/',
    method: 'get',
    params
  }).then(res => {
    success && success(res)
  }).catch(error => {
    fail && fail(error)
  })
}

export function deleteOrder(msg, sucFn, errorFn) {
  return axios({
    method: 'DELETE',
    url: `/api/orders/order_list/${msg.id}/`,
    data: msg
  }).then(res => {
    sucFn && sucFn(res)
  }).catch(error => {
    errorFn && errorFn(error)
  })
}

export function getOrdersDetail(msg, sucFn, errorFn) {
  return axios({
    method: 'get',
    url: `/api/orders/order_list/${msg.id}/get_proxy_detail/`
  }).then(res => {
    sucFn && sucFn(res)
  }).catch(error => {
    errorFn && errorFn(error)
  })
}

export function resetPassword(msg, sucFn, errorFn) {
  return axios({
    method: 'POST',
    url: `/api/orders/order_list/${msg.id}/reset_proxy_password/`,
    data: msg
  }).then(res => {
    sucFn && sucFn(res)
  }).catch(error => {
    errorFn && errorFn(error)
  })
}

export function getAclsList(params, success, fail) {
  return axios({
    url: '/api/servers/acls/',
    method: 'get',
    params
  }).then(res => {
    success && success(res)
  }).catch(error => {
    fail && fail(error)
  })
}

export const checkProxyFn = (msg, sucFn, errorFn) => {
  return axios({
    method: 'GET',
    url: `/api/orders/order_list/${msg.id}/check_proxy/`,
    data: msg
  }).then(res => {
    if (res.code === 2000) {
      typeof sucFn === 'function' && sucFn(res)
    } else {
      typeof errorFn === 'function' && errorFn(res)
    }
  }).catch((error) => {
    typeof errorFn === 'function' && errorFn(error)
  })
}

export const changeProxy = (msg, sucFn, errorFn) => {
  return axios({
    method: 'POST',
    url: `/api/orders/order_list/${msg.id}/reset_proxy/`,
    data: msg
  }).then(res => {
    if (res.code === 2000) {
      typeof sucFn === 'function' && sucFn(res)
    } else {
      typeof errorFn === 'function' && errorFn(res)
    }
  }).catch((error) => {
    typeof errorFn === 'function' && errorFn(error)
  })
}

export const deveryOrder = (msg, sucFn, errorFn) => {
  return axios({
    method: 'POST',
    url: `/api/orders/order_list/${msg.id}/devery_order/`,
    data: msg
  }).then(res => {
    if (res.code === 2000) {
      typeof sucFn === 'function' && sucFn(res)
    } else {
      typeof errorFn === 'function' && errorFn(res)
    }
  }).catch((error) => {
    typeof errorFn === 'function' && errorFn(error)
  })
}

export const editTime = (msg, sucFn, errorFn) => {
  return axios({
    method: 'POST',
    url: `/api/orders/order_list/${msg.id}/update_proxy_expired_at/`,
    data: msg
  }).then(res => {
    if (res.code === 2000) {
      typeof sucFn === 'function' && sucFn(res)
    } else {
      typeof errorFn === 'function' && errorFn(res)
    }
  }).catch((error) => {
    typeof errorFn === 'function' && errorFn(error)
  })
}

export const renew = (msg, sucFn, errorFn) => {
  return axios({
    method: 'POST',
    url: `/api/orders/order_list/${msg.id}/order-renew-checkout/`,
    data: msg
  }).then(res => {
    if (res.code === 2000) {
      typeof sucFn === 'function' && sucFn(res)
    } else {
      typeof errorFn === 'function' && errorFn(res)
    }
  }).catch((error) => {
    typeof errorFn === 'function' && errorFn(error)
  })
}

export const getProxiesItem = (sucFn, errorFn) => {
  return axios({
    method: 'get',
    url: `/api/products/product_collections/`
  }).then(res => {
    if (res.code === 2000) {
      typeof sucFn === 'function' && sucFn(res)
    } else {
      typeof errorFn === 'function' && errorFn(res)
    }
  }).catch((error) => {
    typeof errorFn === 'function' && errorFn(error)
  })
}

export const oneKeyResetProxy = (msg, sucFn, errorFn) => {
  return axios({
    method: 'POST',
    url: `/api/orders/order_list/one_key_reset/`,
    data: msg
  }).then(res => {
    if (res.code === 2000) {
      typeof sucFn === 'function' && sucFn(res)
    } else {
      typeof errorFn === 'function' && errorFn(res)
    }
  }).catch((error) => {
    typeof errorFn === 'function' && errorFn(error)
  })
}