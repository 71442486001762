<template>
    <div>
        <el-card class="box-card" shadow="hover">
            <div v-if="!editVisible">
                <el-card class="search-card" shadow="hover" style="background-color: #febac3;">
                    <el-form :inline="true" :model="formInline" class="search-form">
                        <el-form-item>
                            <el-input 
                                v-model="formInline.search" 
                                @change="onSubmit"
                                @keyup.enter.native="onSubmit"
                                placeholder="Search orders..."
                                prefix-icon="el-icon-search"
                                clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button 
                                @click="onSubmit" 
                                type="info"
                                :loading="searchLoading">
                                <i class="el-icon-search"></i>
                                Search
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <el-form :inline="true" :model="reset_order" class="reset-form">
                        <el-form-item>
                            <el-input 
                                v-model="reset_order.order_ids" 
                                placeholder="id1,id2,id3"
                                @keyup.enter.native="oneKeyReset"
                                clearable>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button 
                                @click="oneKeyReset" 
                                type="info"
                                :loading="resetLoading">
                                <i class="el-icon-refresh"></i>
                                Reset Proxy
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
                <el-table 
                    ref="orderTable"
                    :data="tableData" 
                    style="width: 100%" 
                    header-cell-class-name="table_header"
                    v-loading="tableLoading" 
                    :cell-style="tableRowClassName" 
                    show-summary
                    stripe
                    border
                    @row-click="handleRowClick"
                    @sort-change="handleSortChange">
                    <el-table-column prop="id" label="ID" align="center" width="80" sortable>
                    </el-table-column>
                  <el-table-column prop="old_flag" label="Old" align="center" sortable>
                    <template v-slot="{ row }">
                      <el-tag v-if="row.old_flag === 1" type="danger" style="width: 100%">
                        {{ oldMap[row.old_flag] }}
                      </el-tag>
                      <el-tag v-else type="success" style="width: 100%">{{ oldMap[row.old_flag] }}</el-tag>
                    </template>
                  </el-table-column>
                    <el-table-column prop="shopify_order_number" label="Order No." align="center" width="80" sortable>
                        <template v-slot="{ row }">
                            <a :href="'https://mentosproxy-2.myshopify.com/admin/orders/'+row.shopify_order_id"
                               target="_blank">{{ row.shopify_order_number }}</a>
                        </template>

                    </el-table-column>
                    <el-table-column prop="username" label="User" align="center">
                    </el-table-column>
                    <el-table-column prop="product_name" label="Product" align="center" width="180px">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.product_name+'->'+row.variant_name"
                                        placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">
                                    <el-tag type="success">{{ row.product_name }}->{{ row.variant_name }}</el-tag>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_amount" label="Bill" align="center" sortable>
                        <template v-slot="{ row }">
                            <span>${{ row.pay_amount }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="proxy_num" label="Proxy" align="center">
                    </el-table-column>
                    <el-table-column prop="delivery_num" label="Delivery" align="center" sortable>
                        <template v-slot="{ row }">
                            <el-tag v-if="row.delivery_num === 0" type="danger">{{ row.delivery_num }}</el-tag>
                            <el-tag v-else type="success">{{ row.delivery_num }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pay_status" label="Paid" align="center" sortable>
                        <template v-slot="{ row }">
                            <el-tag v-if="row.pay_status === 0" type="danger" style="width: 100%">
                                {{ payMap[row.pay_status] }}
                            </el-tag>
                            <el-tag v-else type="success" style="width: 100%">{{ payMap[row.pay_status] }}</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="Operation" align="center" width="100px" fixed="right">
                        <template v-slot="{ row, $index }">
                            <el-dropdown 
                                @command="handleCommand($event, row, $index)" 
                                trigger="click" 
                                @click.stop>
                                <el-button type="primary" size="small">
                                    Actions <i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="edit">
                                        <i class="el-icon-edit"></i> Edit
                                    </el-dropdown-item>
                                    <el-dropdown-item command="delete" divided>
                                        <i class="el-icon-delete"></i> Delete
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="Order Date" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="expired_at" label="Expire Date" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.expired_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.expired_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reset_time" label="Reset Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.reset_time" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.reset_time }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="order_id" label="Trans ID" align="center" width="180" sortable></el-table-column>
                    <el-table-column prop="fail_reason" label="Reason" align="center" width="180" sortable></el-table-column>
                    <el-table-column prop="acl_selected" label="ACLs" align="center" width="180">
                        <template v-slot="{ row }">
                            <div class="acl-tags-container">
                                <template v-if="row.acl_selected">
                                    <el-tag 
                                        v-for="id in row.acl_selected.split(',')"
                                        :key="id"
                                        type="info" 
                                        effect="plain" 
                                        size="small"
                                        class="acl-tag">
                                        {{ aclMap[id.trim()] || id }}
                                    </el-tag>
                                </template>
                                <el-tag v-else type="info" effect="plain" size="small">None</el-tag>
                            </div>
                        </template>
                    </el-table-column>

                </el-table>
                <div class="pagination-container">
                    <div class="total-orders">Total Orders: {{ total }}</div>
                    <el-pagination 
                        background 
                        layout="->,prev, pager, next" 
                        :total="total"
                        :current-page.sync="currentPage" 
                        @current-change="handleCurrentChange"
                        class="pagination">
                    </el-pagination>
                </div>
            </div>

            <div v-else v-loading="editDetailLoading" class="orderDetail">
                <div class="page-header-wrapper">
                    <el-page-header 
                        @back="goBack" 
                        content="Order Details"
                        class="custom-page-header">
                        <template slot="title">
                            <span class="back-text"><i class="el-icon-arrow-left"></i> Back</span>
                        </template>
                    </el-page-header>
                    <div class="header-actions">
                        <el-button 
                            data-clipboard-action="copy"
                            :data-clipboard-text="editInfo.proxy_list.map(item => `${item.ip}:${item.port}:${item.username}:${item.password}`).join('\n')"
                            @click="handleCpoy" 
                            id="btn"
                            type="primary"
                            class="copy-button">
                            <i class="el-icon-document-copy"></i> Copy Proxy Info
                        </el-button>
                    </div>
                </div>
                <div class="edit-cards-container">
                    <!-- User Info Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-user"></i>
                            <span>User Information</span>
                        </div>
                        <div class="card-content">
                            <div class="info-item">
                                <span class="label">Username:</span>
                                <el-tag type="success">{{ editInfo.order.username }}</el-tag>
                            </div>
                            <div class="info-item">
                                <span class="label">Product:</span>
                                <el-tag type="success">{{ editInfo.order.product_name }}</el-tag>
                            </div>
                        </div>
                    </el-card>

                    <!-- Account Management Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-setting"></i>
                            <span>Account Management</span>
                        </div>
                        <div class="card-content">
                            <div class="button-group">
                                <el-button type="primary" @click="resetPasswordMethod" :loading="resetpasswordLoading">
                                    <i class="el-icon-key"></i>
                                    Reset Password
                                </el-button>
                                <el-button type="success" @click="checkProxy">
                                    <i class="el-icon-connection"></i>
                                    Check Proxy
                                </el-button>
                            </div>
                        </div>
                    </el-card>

                    <!-- Time Management Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-time"></i>
                            <span>Time Management</span>
                        </div>
                        <div class="card-content">
                            <div class="date-picker-group">
                                <el-date-picker
                                    v-model="form.expired_at"
                                    type="datetime"
                                    placeholder="Please select the expire time"
                                    value-format="timestamp"
                                    style="width: 100%; margin-bottom: 15px;">
                                </el-date-picker>
                                <el-button type="primary" @click="editExpiredTime" :loading="resetExpired_at">
                                    <i class="el-icon-timer"></i>
                                    Extend Expire Time
                                </el-button>
                            </div>
                        </div>
                    </el-card>

                    <!-- Proxy & Delivery Card -->
                    <el-card class="edit-card" shadow="hover">
                        <div slot="header" class="card-header">
                            <i class="el-icon-connection"></i>
                            <span>Proxy & Delivery</span>
                        </div>
                        <div class="card-content">
                            <div class="button-group">
                                <el-button type="warning" @click="resetChangeProxy" :loading="ChangeProxyLoading">
                                    <i class="el-icon-refresh"></i>
                                    Change Proxy
                                </el-button>
                                <el-button type="success" @click="deveryOrderAction">
                                    <i class="el-icon-truck"></i>
                                    Delivery
                                </el-button>
                            </div>
                        </div>
                    </el-card>
                </div>
                <el-table :data="editInfo.proxy_list" style="width: 100%" header-cell-class-name="tableDetail_header"
                          show-summary>
                    <el-table-column type="index" align="center" width="80" label="Index"></el-table-column>
                    <el-table-column prop="ip" label="Ip" align="center">
                    </el-table-column>
                    <el-table-column prop="port" label="Port" align="center">
                    </el-table-column>
                    <el-table-column prop="username" label="Username" align="center">
                    </el-table-column>
                    <el-table-column prop="password" label="Password" align="center">
                    </el-table-column>
                    <el-table-column prop="created_at" label="Create Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.created_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="expired_at" label="Expire Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.expired_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ row.expired_at.substr(0, 10) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="updated_at" label="Update Time" align="center" width="120">
                        <template v-slot="{ row }">
                            <el-tooltip class="item" effect="dark" :content="row.updated_at" placement="top-start"
                                        :content-style="{ 'max-height': '60px' }">
                                <div class="ellipsis">{{ timeDifference(row.updated_at) }}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="Status" align="center" width="100" sortable>
                        <template v-slot="{ row }">
                            <el-tag v-if="row.status" type="success" style="width: 100%">Available</el-tag>
                            <el-tag v-else type="danger" style="width: 100%">Unavailable</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bing_delay" label="bing(ms)" align="center" width="100" sortable>
                  </el-table-column>

                  <el-table-column prop="google_delay" label="google(ms)" align="center" width="100" sortable>
                  </el-table-column>

                  <el-table-column prop="amazon_delay" label="amazon(ms)" align="center" width="100" sortable>
                  </el-table-column>
                </el-table>
            </div>
        </el-card>

        <!-- 添加订单详情对话框 -->
        <el-dialog
            title="Order Details"
            :visible.sync="dialogVisible"
            width="90%"
            :before-close="handleClose">
            <div class="order-detail-dialog">
                <div class="expand-content">
                    <!-- Order Information -->
                    <div class="expand-section order-info">
                        <div class="section-title">
                            <i class="el-icon-shopping-cart-2"></i>
                            <span>Order Information</span>
                        </div>
                        <div class="section-content">
                            <div class="info-row">
                                <div class="info-item">
                                    <span class="label">Order No.</span>
                                    <span class="value">
                                        <a :href="'https://mentosproxy-2.myshopify.com/admin/orders/'+currentOrder.shopify_order_id"
                                           target="_blank">{{ currentOrder.shopify_order_number }}</a>
                                    </span>
                                </div>
                                <div class="info-item">
                                    <span class="label">Transaction ID</span>
                                    <span class="value">{{ currentOrder.order_id }}</span>
                                </div>
                                <div class="info-item">
                                    <span class="label">Status</span>
                                    <div class="value-group">
                                        <span class="value tag" :class="currentOrder.pay_status === 1 ? 'success' : 'danger'">
                                            {{ payMap[currentOrder.pay_status] }}
                                        </span>
                                        <span class="value tag" :class="currentOrder.is_expired ? 'danger' : 'success'">
                                            {{ currentOrder.is_expired ? 'Expired' : 'Active' }}
                                        </span>
                                    </div>
                                </div>
                                <div class="info-item">
                                    <span class="label">Product</span>
                                    <span class="value">{{ currentOrder.product_name }} - {{ currentOrder.variant_name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- User & Status -->
                    <div class="expand-section">
                        <div class="section-title">
                            <i class="el-icon-user"></i>
                            <span>User & Status</span>
                        </div>
                        <div class="section-content">
                            <div class="info-row">
                                <div class="info-item">
                                    <span class="label">Username</span>
                                    <span class="value">{{ currentOrder.username }}</span>
                                </div>
                                <div class="info-item">
                                    <span class="label">Order Status</span>
                                    <span class="value tag" :class="currentOrder.pay_status === 1 ? 'success' : 'danger'">
                                        {{ payMap[currentOrder.pay_status] }}
                                    </span>
                                </div>
                                <div class="info-item">
                                    <span class="label">Proxy Count</span>
                                    <span class="value">{{ currentOrder.proxy_num }}</span>
                                </div>
                                <div class="info-item">
                                    <span class="label">Delivery Count</span>
                                    <span class="value tag" :class="currentOrder.delivery_num === 0 ? 'danger' : 'success'">
                                        {{ currentOrder.delivery_num }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Time & Additional Info -->
                    <div class="expand-section">
                        <div class="section-title">
                            <i class="el-icon-time"></i>
                            <span>Time & Additional Info</span>
                        </div>
                        <div class="section-content">
                            <div class="info-row">
                                <div class="info-item">
                                    <span class="label">Created At</span>
                                    <span class="value">{{ currentOrder.created_at }}</span>
                                </div>
                                <div class="info-item">
                                    <span class="label">Expires At</span>
                                    <span class="value">{{ currentOrder.expired_at }}</span>
                                </div>
                                <div class="info-item">
                                    <span class="label">Reset Time</span>
                                    <span class="value">{{ currentOrder.reset_time || 'Not Reset' }}</span>
                                </div>
                                <div class="info-item">
                                    <span class="label">ACL Permissions</span>
                                    <div class="acl-tags-container">
                                        <template v-if="currentOrder.acl_selected">
                                            <el-tag 
                                                v-for="id in currentOrder.acl_selected.split(',')"
                                                :key="id"
                                                type="info"
                                                effect="plain"
                                                size="small"
                                                class="acl-tag">
                                                {{ aclMap[id.trim()] || id }}
                                            </el-tag>
                                        </template>
                                        <el-tag v-else type="info" effect="plain" size="small">None</el-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Close</el-button>
            </span>
        </el-dialog>

        <!-- 修改返回顶部按钮 -->
        <div class="back-to-top" v-show="showBackTop" @click="scrollToTop">
            <div class="backtop-content">
                <i class="el-icon-caret-top"></i>
                <span>TOP</span>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getOrdersList,
    deleteOrder,
    getOrdersDetail,
    resetPassword,
    editTime,
    changeProxy, oneKeyResetProxy, checkProxyFn,deveryOrder, getAclsList
} from "@/api/dashboard/orders"
import ClipboardJS from "clipboard";

export default {
    data() {
        return {
            payMap: {
                0: 'Unpaid',
                1: 'Paid'
            },
            oldMap: {
                0: 'New',
                1: 'Old'
            },
            formInline: {
                search: ''
            },
            reset_order: {
                "order_ids": "",
            },
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            editVisible: false,
            form: {
                expired_at: ""
            },
            editInfo: {
                order: {
                    username: "",
                    product_name: ""
                },
                proxy_list: []
            },
            editId: null,
            resetpasswordLoading: false,
            resetExpired_at: false,
            ChangeProxyLoading: false,
            searchLoading: false,
            resetLoading: false,
            sortBy: '',
            sortOrder: '',
            dialogVisible: false,
            currentOrder: {},
            aclMap: {},
            showBackTop: false
        }
    },
    mounted() {
        new this.$wow.WOW().init()
        const {params} = this.$route
        params.username && (this.formInline.search = params.username)
        this.getTableData()
        window.addEventListener('scroll', this.handleScroll);
        // 监听右侧内容区域的滚动
        const contentEl = document.querySelector('.el-card .card-content');
        if (contentEl) {
            contentEl.addEventListener('scroll', this.handleScroll);
        }
    },
    created() {
        this.getAclsData()
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        const contentEl = document.querySelector('.el-card .card-content');
        if (contentEl) {
            contentEl.removeEventListener('scroll', this.handleScroll);
        }
    },
    methods: {
        tableRowClassName({row}) {
            return row.delivery_num === 0 && row.pay_status === 1 ? 'background-color: red; color:white;' : ''
        },
        async onSubmit() {
            this.searchLoading = true;
            try {
                await this.getTableData();
                this.$message.success('Search completed');
            } catch (error) {
                this.$message.error('Search failed: ' + error.message);
            } finally {
                this.searchLoading = false;
            }
        },
        async oneKeyReset() {
            this.$confirm('Are you sure to reset these orders?', 'Reset These Orders', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                oneKeyResetProxy(this.reset_order, (res) => {
                    this.$message.success(res.msg)
                }, (res) => {
                    this.$message.warning(res.msg)
                })

            }).catch(() => {
            });
        },
        timeDifference(previous) {
            if (!previous) return '';
            
            // Convert UTC time string to local time
            const current = new Date();
            const previousDate = new Date(previous + 'Z'); // Add Z to indicate UTC time
            
            const msPerMinute = 60 * 1000;
            const msPerHour = msPerMinute * 60;
            const msPerDay = msPerHour * 24;
            const msPerMonth = msPerDay * 30;
            const msPerYear = msPerDay * 365;

            const elapsed = current - previousDate;

            if (elapsed < msPerMinute) {
                const seconds = Math.round(elapsed/1000);
                return seconds <= 0 ? 'just now' : `${seconds} seconds ago`;
            } 
            else if (elapsed < msPerHour) {
                const minutes = Math.round(elapsed/msPerMinute);
                return `${minutes} minutes ago`;
            } 
            else if (elapsed < msPerDay) {
                const hours = Math.round(elapsed/msPerHour);
                return `${hours} hours ago`;
            } 
            else if (elapsed < msPerMonth) {
                const days = Math.round(elapsed/msPerDay);
                return `${days} days ago`;
            } 
            else if (elapsed < msPerYear) {
                const months = Math.round(elapsed/msPerMonth);
                return `${months} months ago`;
            } 
            else {
                const years = Math.round(elapsed/msPerYear);
                return `${years} years ago`;
            }
        },
        
        formatDate(dateStr) {
            if (!dateStr) return '';
            // 直接使用时间字符串创建Date对象，浏览器会自动处理时区转换
            const date = new Date(dateStr);
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        },
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 10
            const param = {search: this.formInline.search, limit: 10, offset}
            this.tableLoading = true
            getOrdersList(param, (res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
            }, (res) => {
                this.tableLoading = false
                this.tableData = []
                this.total = 0
                this.$message.warning(res.msg)
            })
        },


        // 删除用户
        deleteOrder(row, index) {
            this.$confirm(`UserName: ${row.username}`, 'Delete Order ?', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                deleteOrder({id: row.id}, (res) => {
                    this.dialogVisible = false;
                    this.currentOrder = {};
                    
                    this.currentPage = this.currentPage === 1 ? this.currentPage : (this.tableData.length == 1 && index === 0) ? this.currentPage - 1 : this.currentPage
                    this.getTableData()
                })
            }).catch(() => {

            })
        },
        // 修改用户
        editOrder(row) {
            this.editVisible = !this.editVisible
            this.editDetailLoading = true
            this.editId = row.id
            getOrdersDetail({id: row.id}, (res) => {
                this.editDetailLoading = false
                this.$set(this, 'editInfo', res.data)
            }, (res) => {
                this.editVisible = !this.editVisible
                this.$message.warning(res.msg)
            })
        },
        //修改密码
        resetPassword() {
            return new Promise((resolve, reject) => {
                this.resetpasswordLoading = true;
                resetPasswordApi({id: this.form.id}, (res) => {
                    this.resetpasswordLoading = false;
                    this.$message.success("success");
                    resolve();
                }, (error) => {
                    this.resetpasswordLoading = false;
                    reject(error);
                });
            });
        },
        goBack() {
            this.editVisible = !this.editVisible
            this.getTableData()
            this.form.expired_at = ""
            this.$set(this, "editInfo", {
                order: {
                    username: "",
                    product_name: ""
                },
                proxy_list: []
            })
        },
        resetPasswordMethod() {
            this.resetpasswordLoading = !this.resetpasswordLoading
            resetPassword({id: this.editId}, res => {
                this.resetpasswordLoading = !this.resetpasswordLoading
                this.$message.success("Successful operation")
            }, (res) => {
                this.resetpasswordLoading = !this.resetpasswordLoading
                this.$message.error(res.msg)
            })
        },
        editExpiredTime() {
            if (!this.form.expired_at) return this.$message.warning("Please select the expire time")
            this.resetExpired_at = !this.resetExpired_at
            editTime({id: this.editId, expired_at: this.form.expired_at}, res => {
                this.resetExpired_at = !this.resetExpired_at
                this.$message.success("Successful operation")
            }, (res) => {
                this.resetExpired_at = !this.resetExpired_at
                this.$message.error(res.msg)
            })
        },
        resetChangeProxy() {
            this.ChangeProxyLoading = !this.ChangeProxyLoading
            changeProxy({id: this.editId}, res => {
                this.ChangeProxyLoading = !this.ChangeProxyLoading
                this.$message.success(res.msg)
            }, (res) => {
                this.ChangeProxyLoading = !this.ChangeProxyLoading
                this.$message.error(res.msg)
            })
        },
        checkProxy() {
            checkProxyFn({id: this.editId}, res => {
                    this.$message.success(res.msg)
                }, (res) => {
                    this.$message.error(res.msg)
                }
            )
        },
        deveryOrderAction() {
            deveryOrder({id: this.editId}, res => {
                    this.$message.success(res.msg)
                }, (res) => {
                    this.$message.error(res.msg)
                }
            )
        },
        handleCpoy() {
            const clipboard = new ClipboardJS('#btn')
            clipboard.on('success', (e) => {

                e.clearSelection()
                // 释放内存
                clipboard.destroy()
                this.$message.success("Successful replication")
            })
            // 复制失败
            clipboard.on('error', (e) => {
                clipboard.destroy()
                this.$message.error("Replication failure")
            })
        },

        handleSortChange({ prop, order }) {
            this.sortBy = prop;
            this.sortOrder = order;
            this.getTableData();
        },
        handleCommand(command, row, index) {
            switch (command) {
                case 'edit':
                    this.editOrder(row);
                    break;
                case 'delete':
                    this.$confirm('Are you sure to delete this order?', 'Warning', {
                        confirmButtonText: 'Confirm',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }).then(() => {
                        this.deleteOrder(row, index);
                    }).catch(() => {
                        this.$message.info('Delete cancelled');
                    });
                    break;
            }
        },
        handleRowClick(row, column) {
            // 如果点击的是操作列，则不打开对话框
            if (column.label === 'Operation') {
                return;
            }
            this.currentOrder = {...row};
            this.dialogVisible = true;
        },
        handleClose(done) {
            this.currentOrder = {};
            done();
        },
        getRowClassName({ row }) {
            return row.is_expired ? 'expired-row' : '';
        },
        formatAcls(aclString) {
            if (!aclString) return 'None';
            return aclString.split(',')
                .map(id => this.aclMap[id] || id)
                .join(', ');
        },
        getAclsData() {
            const params = { limit: 500, offset: 0 }
            getAclsList(params, 
                (res) => {
                    if (res && res.code === 2000 && res.data && res.data.data) {
                        this.aclMap = res.data.data.reduce((acc, item) => {
                            acc[item.id] = item.name
                            return acc
                        }, {})
                    }
                },
                (error) => {
                    console.error('Failed to fetch ACLs:', error)
                }
            )
        },
        handleScroll() {
            // 获取页面滚动位置
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            // 获��右侧内容区域滚动位置
            const contentEl = document.querySelector('.el-card .card-content');
            const contentScrollTop = contentEl ? contentEl.scrollTop : 0;
            
            // 当任一区域滚动超过300px时显示返回顶部按钮
            this.showBackTop = scrollTop > 300 || contentScrollTop > 300;
        },
        scrollToTop() {
            // 页面滚动到顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            // 右侧内容区域滚动到顶部
            const contentEl = document.querySelector('.el-card .card-content');
            if (contentEl) {
                contentEl.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    },
    watch: {
        $route: {
            handler() {
                const {params} = this.$route
                params.username && (this.formInline.search = params.username)
                this.getTableData()
            },
            deep: true
        }
    }

}

</script>

<style lang="scss" scoped>
.box-card {
    margin: 20px;
    
    .search-card {
        margin-bottom: 20px;
        border-radius: 8px;
        background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%) !important;
        
        .search-form, .reset-form {
            padding: 15px;
            .el-form-item {
                margin: 0 15px 0 0;
            }
            .el-input {
                width: 300px;
                .el-input__inner {
                    border-radius: 20px;
                    &:focus {
                        border-color: #ff9a9e;
                    }
                }
            }
        }
    }

    :deep(.table_header) {
        background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
        color: #303133;
        font-weight: 600;
        padding: 12px 0;
    }

    .el-button {
        border-radius: 20px;
        padding: 10px 20px;
        transition: all 0.3s ease;
        font-weight: 500;
        letter-spacing: 0.5px;
        
        &.el-button--primary {
            background: linear-gradient(135deg, #FF79A7 0%, #FF96B7 100%);
            border-color: #FF79A7;
            color: white;
            box-shadow: 0 4px 15px rgba(255, 121, 167, 0.3);
            
            &:hover {
                transform: translateY(-2px);
                background: linear-gradient(135deg, #FF96B7 0%, #FFB4CB 100%);
                box-shadow: 0 6px 20px rgba(255, 121, 167, 0.4);
            }
            
            &:active {
                transform: translateY(1px);
            }
        }
        
        &.el-button--success {
            background: linear-gradient(135deg, #7BE495 0%, #9EEDB5 100%);
            border-color: #7BE495;
            color: white;
            box-shadow: 0 4px 15px rgba(123, 228, 149, 0.3);
            
            &:hover {
                transform: translateY(-2px);
                background: linear-gradient(135deg, #9EEDB5 0%, #BFFFD4 100%);
                box-shadow: 0 6px 20px rgba(123, 228, 149, 0.4);
            }
        }
        
        &.el-button--warning {
            background: linear-gradient(135deg, #FFB347 0%, #FFD080 100%);
            border-color: #FFB347;
            color: white;
            box-shadow: 0 4px 15px rgba(255, 179, 71, 0.3);
            
            &:hover {
                transform: translateY(-2px);
                background: linear-gradient(135deg, #FFD080 0%, #FFE1A8 100%);
                box-shadow: 0 6px 20px rgba(255, 179, 71, 0.4);
            }
        }
        
        &.el-button--danger {
            background: linear-gradient(135deg, #FF7285 0%, #FF9EAA 100%);
            border-color: #FF7285;
            color: white;
            box-shadow: 0 4px 15px rgba(255, 114, 133, 0.3);
            
            &:hover {
                transform: translateY(-2px);
                background: linear-gradient(135deg, #FF9EAA 0%, #FFC1C8 100%);
                box-shadow: 0 6px 20px rgba(255, 114, 133, 0.4);
            }
        }
        
        &.el-button--info {
            background: linear-gradient(135deg, #8E9AAF 0%, #B5BEC9 100%);
            border-color: #8E9AAF;
            color: white;
            box-shadow: 0 4px 15px rgba(142, 154, 175, 0.3);
            
            &:hover {
                transform: translateY(-2px);
                background: linear-gradient(135deg, #B5BEC9 0%, #D2D8DE 100%);
                box-shadow: 0 6px 20px rgba(142, 154, 175, 0.4);
            }
        }

        // 禁用状态
        &.is-disabled {
            background: #F5F7FA !important;
            border-color: #E4E7ED !important;
            color: #C0C4CC !important;
            box-shadow: none !important;
            
            &:hover {
                transform: none !important;
                box-shadow: none !important;
            }
        }

        // 加载状态
        &.is-loading {
            opacity: 0.8;
            pointer-events: none;
        }

        // 图标样式
        [class^="el-icon-"] {
            margin-right: 5px;
            font-weight: bold;
        }
    }

    .pagination-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        padding: 10px 0;

        .total-orders {
            color: #606266;
            font-size: 14px;
        }
    }
}

.edit-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.edit-card {
    transition: all 0.3s ease;
    
    &:hover { transform: translateY(-5px); }

    .card-header {
        display: flex;
        align-items: center;
        font-weight: bold;
        
        i {
            margin-right: 8px;
            color: #409EFF;
        }
    }

    .card-content {
        padding: 10px 0;

        .info-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            
            &:last-child { margin-bottom: 0; }

            .label {
                min-width: 80px;
                color: #909399;
                margin-right: 10px;
            }
        }
    }
}

.page-header-wrapper {
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e7ed 100%);
    padding: 16px 24px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-detail-dialog {
  .expand-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    padding: 16px;
    
    .expand-section {
      background: #fff;
      border-radius: 12px;
      padding: 20px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      border: 1px solid rgba(0, 0, 0, 0.05);
      
      &.order-info {
        grid-column: 1 / -1;
      }
      
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
      }
      
      .section-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 12px;
        border-bottom: 2px solid #f5f7fa;
        
        i {
          font-size: 20px;
          margin-right: 12px;
        }
        
        span {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.5px;
        }
      }

      .section-content {
        .info-row {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 16px;
          
          .info-item {
            padding: 12px;
            background: #f9fafc;
            border-radius: 8px;
            transition: all 0.3s ease;
            
            &:hover {
              background: #f0f2f5;
            }
            
            .label {
              display: block;
              color: #909399;
              font-size: 13px;
              margin-bottom: 8px;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: 0.5px;
            }
            
            .value {
              color: #303133;
              font-size: 14px;
              font-weight: 500;
              
              &.price {
                color: #F56C6C;
                font-size: 16px;
                font-weight: 600;
              }
              
              &.tag {
                display: inline-block;
                padding: 4px 12px;
                border-radius: 6px;
                font-size: 13px;
                font-weight: 500;
                
                &.success { 
                  background: #f0f9eb;
                  color: #67c23a;
                  border: 1px solid #c2e7b0;
                }
                &.danger { 
                  background: #fef0f0;
                  color: #f56c6c;
                  border: 1px solid #fbc4c4;
                }
                &.warning { 
                  background: #fdf6ec;
                  color: #e6a23c;
                  border: 1px solid #f5dab1;
                }
                &.info { 
                  background: #f4f4f5;
                  color: #909399;
                  border: 1px solid #d3d4d6;
                }
              }
            }
          }
        }
      }
    }
  }
}

// 优化对话框样式
.el-dialog {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  
  .el-dialog__header {
    padding: 24px;
    background: #f9fafc;
    border-bottom: 1px solid #ebeef5;
    
    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
      color: #303133;
    }
  }
  
  .el-dialog__body {
    padding: 32px 24px;
  }
  
  .el-dialog__footer {
    padding: 20px 24px;
    background: #f9fafc;
    border-top: 1px solid #ebeef5;
  }
}

.acl-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    
    .acl-tag {
        margin: 0;
        background: #f4f4f5;
        border-color: #e9e9eb;
        color: #909399;
        
        &:hover {
            background: #e9e9eb;
        }
    }
}

// 表格中的标签样式
.el-table {
    .acl-tags-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 4px;
        min-height: 24px;
        
        .el-tag {
            margin: 2px;
            
            &.el-tag--info.el-tag--plain {
                background: #f4f4f5;
                border-color: #e9e9eb;
                color: #909399;
                
                &:hover {
                    background: #e9e9eb;
                }
            }
        }
    }
}

// 搜索卡片中的按钮特殊样式
.search-card {
    .el-button {
        backdrop-filter: blur(5px);
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: white;
        
        &:hover {
            background: rgba(255, 255, 255, 0.3);
            border-color: rgba(255, 255, 255, 0.4);
        }
        
        [class^="el-icon-"] {
            color: white;
        }
    }
}

.back-to-top {
    position: fixed;
    right: 40px;
    bottom: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 1000;
    
    .backtop-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: linear-gradient(135deg, #FF79A7 0%, #FF96B7 100%);
        box-shadow: 0 4px 12px rgba(255, 121, 167, 0.3);
        border-radius: 50%;
        color: white;
        transition: all 0.3s ease;
        
        &:hover {
            transform: translateY(-2px);
            background: linear-gradient(135deg, #FF96B7 0%, #FFB4CB 100%);
            box-shadow: 0 6px 16px rgba(255, 121, 167, 0.4);
        }
        
        i {
            font-size: 16px;
            margin-bottom: 2px;
        }
        
        span {
            font-size: 12px;
            font-weight: 500;
        }
    }

    // 添加动画效果
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>