export default {
  login: {
    title: 'ログイン',
    username: 'ユーザー名',
    password: 'パスワード',
    remember: '記憶する',
    submit: 'ログイン',
    forgot: 'パスワードをお忘れですか？',
    logout: 'ログアウト',
    loginViaEmail: 'メールでログイン',
    loginViaDiscord: 'Discordでログイン',
    emailPlaceholder: 'メールアドレスを入力',
    passwordPlaceholder: 'パスワードを入力',
    resetPassword: 'パスワードをリセット',
    sendCode: '確認コードを送信'
  },
  nav: {
    home: 'ホーム',
    product: '製品',
    dashboard: 'ダッシュボード',
    support: 'サポート',
    faq: 'よくある質問',
    contact: 'お問い合わせ',
    userList: 'ユーザーリスト',
    logout: 'ログアウト'
  },
  dashboard: {
    showMenu: 'メニューを表示',
    hideMenu: 'メニューを隠す',
    proxies: 'プロキシ',
    servers: 'サーバー',
    settings: '設定',
    profile: 'プロフィール',
    backToTop: 'トップへ戻る'
  },
  common: {
    search: '検索',
    add: '追加',
    edit: '編集',
    delete: '削除',
    cancel: 'キャンセル',
    confirm: '確認',
    save: '保存',
    success: '成功',
    error: 'エラー',
    loading: '読み込み中...',
    noData: 'データなし',
    operation: '操作',
    status: 'ステータス',
    actions: 'アクション'
  }
}
