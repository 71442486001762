<template>
    <div>
        <el-card class="box-card" shadow="never">
            <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header"
                      v-loading="tableLoading"
                      @row-click="handleClickRow" :row-class-name="rowHighlight">
                <el-table-column prop="id" label=" Order ID" align="center">
                    <template v-slot="{ row }">
                        <div class="order-cell">
                            <span>{{ row.id }}</span>
                            <el-tag v-if="row.is_expired" 
                                   type="danger" 
                                   size="mini" 
                                   class="expired-tag">
                                Expired
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="product_name" label="Product" align="center">
                    <template v-slot="{ row }">
                        <span :class="{ 'expired-text': row.is_expired }">{{ row.product_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="product_quantity" label="Quantity" align="center">
                    <template v-slot="{ row }">
                        <span :class="{ 'expired-text': row.is_expired }">{{ row.product_quantity }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="pay_amount" label="Amount" align="center">
                    <template v-slot="{ row }">
                        <span :class="{ 'unpaid': row.pay_status === 0, 'expired-text': row.is_expired }">{{ '$' + row.pay_amount }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="pay_status" label="Paid" align="center">
                    <template v-slot="{ row }">
                        <span :class="{ 'unpaid': row.pay_status === 0, 'expired-text': row.is_expired }">{{ payMap[row.pay_status] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Order Date" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis" :class="{ 'expired-text': row.is_expired }">
                                {{ row.created_at.substr(0, 10) }}
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="expired_at" label="Expiry Date" align="center" width="120">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.expired_at" placement="top-start"
                                    :content-style="{ 'max-height': '60px' }">
                            <div class="ellipsis" :class="{ 'expired-text': row.is_expired }">
                                {{ row.expired_at.substr(0, 10) }}
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="Action" align="center">
                    <template v-slot="{ row }">
                        <div>
                            <el-button size="mini" type="danger" :disabled="row.pay_status === 0"
                                       @click.stop="handleRenew(row.id)">renew
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="->,prev, pager, next" :total="total" style="margin-top: 20px;"
                           :page-size="5"
                           :current-page.sync="currentPage" @current-change="handleCurrentChange">
            </el-pagination>
        </el-card>
        <div style="margin: 20px 0px;"></div>
        <el-card class="box-card1" shadow="hover">
            <div slot="header" class="clearfix">
                <div class="card-header">
                    <div class="title">
                        <i class="el-icon-connection"></i>
                        <span>Proxies</span>
                    </div>
                    <div class="action-buttons">
                        <el-button
                            class="action-button"
                            data-clipboard-action="copy"
                            :data-clipboard-text="detailTableData.map(item => `${item.ip}:${item.port}:${item.username}:${item.password}`).join('\n')"
                            @click="handleCpoy"
                            id="btn"
                            icon="el-icon-document-copy">
                            Copy
                        </el-button>
                        <el-button
                            class="action-button"
                            @click="checkProxy"
                            icon="el-icon-refresh">
                            Check Proxy
                        </el-button>
                        <el-button
                            class="action-button"
                            @click="exportProxies"
                            icon="el-icon-download">
                            Export
                        </el-button>
                    </div>
                </div>
            </div>
            <el-table 
                :data="detailTableData" 
                style="width: 100%" 
                header-cell-class-name="table-header"
                v-loading="detailTableLoading"
                :header-cell-style="{
                    background: '#f5f7fa',
                    color: '#606266',
                    fontWeight: 'bold'
                }"
                :row-style="{ cursor: 'pointer' }"
                @row-click="handleRowClick">
                <el-table-column prop="ip" label="IP" align="center">
                    <template v-slot="{ row }">
                        <span class="proxy-text">{{ row.ip }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="port" label="Port" align="center">
                    <template v-slot="{ row }">
                        <span class="proxy-text">{{ row.port }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="Username" align="center">
                    <template v-slot="{ row }">
                        <span class="proxy-text">{{ row.username }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="password" label="Password" align="center">
                    <template v-slot="{ row }">
                        <span class="proxy-text">{{ row.password }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="Status" align="center" width="100">
                    <template v-slot="{ row }">
                        <el-tag v-if="row.status" type="success" effect="dark" size="small">Available</el-tag>
                        <el-tag v-else type="danger" effect="dark" size="small">Unavailable</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="bing_delay" label="Bing" align="center" width="100" sortable>
                    <template v-slot="{ row }">
                        <span :class="['delay-text', getDelayClass(row.bing_delay)]">
                            {{ row.bing_delay }}ms
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="google_delay" label="Google" align="center" width="100" sortable>
                    <template v-slot="{ row }">
                        <span :class="['delay-text', getDelayClass(row.google_delay)]">
                            {{ row.google_delay }}ms
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="amazon_delay" label="Amazon" align="center" width="100" sortable>
                    <template v-slot="{ row }">
                        <span :class="['delay-text', getDelayClass(row.amazon_delay)]">
                            {{ row.amazon_delay }}ms
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Create Time" align="center" width="160">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.created_at" placement="top">
                            <div class="time-text">{{ formatDate(row.created_at) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="updated_at" label="Last Update" align="center" width="160">
                    <template v-slot="{ row }">
                        <el-tooltip class="item" effect="dark" :content="row.updated_at" placement="top">
                            <div class="time-text update-time">{{ timeDifference(row.updated_at) }}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>

import {getOrdersList, getOrdersDetail, renew, checkProxyFn} from "@/api/dashboard/orders"
import ClipboardJS from 'clipboard'

export default {

    data() {
        return {
            detailTableData: [],
            tableData: [],
            currentPage: 1,
            total: 0,
            tableLoading: false,
            detailTableLoading: false,
            dialogFormVisible: false,
            EditLoading: false,
            product_type: '',
            payMap: {
                0: 'Unpaid',
                1: 'Paid'
            },
            selectedRowId: null

        }
    },
    mounted() {
    },
    watch: {
        $route: {
            handler() {
                const {id} = this.$route.params
                this.product_type = id
                this.currentPage = 1
                this.getTableData()
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.getTableData()
        },
        getTableData() {
            const offset = (this.currentPage - 1) * 5
            const param = {limit: 5, offset}
            this.product_type && (param.product_type = this.product_type)
            this.tableLoading = true
            this.detailTableData = []
            getOrdersList(param, (res) => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.tableLoading = false
                this.tableData.length && this.handleClickRow(this.tableData[0])
            }, (res) => {
                this.tableLoading = false
                this.tableData = []
                this.total = 0
                this.$message.warning(res.msg)
            })
        },
        timeDifference(previous) {
            if (!previous) return '';
            
            // 获取当前本地时间戳
            const currentTime = new Date().getTime();
            
            // 将UTC时间字符串转换为本地时间戳
            const previousUTC = new Date(previous + 'Z'); // 添加 'Z' 表明这是 UTC 时间
            const previousTime = previousUTC.getTime();
            
            const msPerMinute = 60 * 1000;
            const msPerHour = msPerMinute * 60;
            const msPerDay = msPerHour * 24;
            const msPerMonth = msPerDay * 30;
            const msPerYear = msPerDay * 365;

            const elapsed = currentTime - previousTime;

            if (elapsed < msPerMinute) {
                const seconds = Math.round(elapsed/1000);
                return seconds + ' seconds ago';
            } else if (elapsed < msPerHour) {
                const minutes = Math.round(elapsed/msPerMinute);
                return minutes + ' minutes ago';
            } else if (elapsed < msPerDay) {
                const hours = Math.round(elapsed/msPerHour);
                return hours + ' hours ago';
            } else if (elapsed < msPerMonth) {
                const days = Math.round(elapsed/msPerDay);
                return days + ' days ago';
            } else if (elapsed < msPerYear) {
                const months = Math.round(elapsed/msPerMonth);
                return months + ' months ago';
            } else {
                const years = Math.round(elapsed/msPerYear);
                return years + ' years ago';
            }
        },
        rowHighlight({row}) {
            if (row.is_expired) {
                return 'expired-row';
            }
            if (row.id === this.selectedRowId) {
                return 'highlight-row';
            }
            return '';
        },
        handleClickRow(row) {
            const {id} = row
            this.selectedRowId = row.id
            this.detailTableData = []
            this.detailTableLoading = true
            getOrdersDetail({id}, res => {
                this.detailTableLoading = false
                this.detailTableData = res.data.proxy_list
            }, res => {
                this.detailTableLoading = false
                this.detailTableData = []
                this.$message.warning(res.msg)
            })
        },
        checkProxy() {
            //获取当前点击的订单id 传给后台 未点击时默认第一个
            const {id} = this.tableData.find(item => item.id === this.selectedRowId) || this.tableData[0]
            checkProxyFn({id}, res => {
                this.$message.success("Check proxy success,please wait a moment...")
            }, res => {
                this.$message.warning(res.msg)
            })

        },
        handleCpoy() {
            const clipboard = new ClipboardJS('#btn')
            clipboard.on('success', (e) => {

                e.clearSelection()
                // 释放内存
                clipboard.destroy()
                this.$message.success("Successful replication")
            })
            // 复制失败
            clipboard.on('error', (e) => {
                clipboard.destroy()
                this.$message.error("Replication failure")
            })
        },
        handleRenew(id) {

            renew({id}, res => {
                const {checkout_url} = res.data
                const a = document.createElement("a")
                a.href = checkout_url
                a.target = "_blank"
                document.body.appendChild(a)
                a.click()
                a.remove()
            }, res => {

                this.$message.warning(res.msg)
            })
        },
        exportProxies() {
            const content = this.detailTableData.map(item => 
                `${item.ip}:${item.port}:${item.username}:${item.password}`
            ).join('\n');
            
            const blob = new Blob([content], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            
            link.href = url;
            link.download = 'proxies.txt';
            document.body.appendChild(link);
            link.click();
            
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            
            this.$message({
                message: 'Proxies exported successfully!',
                type: 'success',
                duration: 2000
            });
        },
        
        handleRowClick(row) {
            // 点击行时自动复制该行的代理信息
            const proxyString = `${row.ip}:${row.port}:${row.username}:${row.password}`;
            const tempInput = document.createElement('input');
            tempInput.value = proxyString;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            
            this.$message({
                message: 'Proxy copied to clipboard!',
                type: 'success',
                duration: 1500
            });
        },
        getDelayClass(delay) {
            if (!delay) return 'delay-none';
            if (delay < 500) return 'delay-fast';
            if (delay < 1000) return 'delay-medium';
            return 'delay-slow';
        },
        formatDate(dateStr) {
            if (!dateStr) return '';
            const date = new Date(dateStr);
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.box-card {
  :deep(.el-card) {
    border-radius: 18px;
  }

  :deep(.el-form) {
    font-family: "Pang";
  }

  :deep(thead) {
    font-family: "Pang";
  }

  :deep(.el-form-item__label) {
    font-size: 20px;
    color: #fff;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  :deep(.el-form-item) {
    margin-bottom: 0;
  }


  .demo-form-inline {
    :deep(.el-button) {
      font-family: "Pang";
      background-color: #d6227a;
      color: #fff;
      border-radius: 10px;
    }
  }

  :deep(.table_header) {
    background-color: #f1408c !important;
    color: #fff;
  }

  :deep(.table_header:first-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :deep(.table_header:nth-child(8)) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
    background-color: #f1408c;
  }


}

.unpaid {
  color: red;
}

.box-card1 {
    border-radius: 8px;
    
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        
        .title {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 18px;
            color: #303133;
            
            i {
                font-size: 20px;
                color: #409EFF;
            }
        }
        
        .action-buttons {
            display: flex;
            gap: 12px;
            
            .action-button {
                padding: 8px 16px;
                border-radius: 6px;
                font-weight: 500;
                transition: all 0.3s;
                
                &:nth-child(1) {
                    background-color: #67C23A;
                    border-color: #67C23A;
                    color: white;
                    
                    &:hover {
                        background-color: #85ce61;
                        border-color: #85ce61;
                    }
                }
                
                &:nth-child(2) {
                    background-color: #409EFF;
                    border-color: #409EFF;
                    color: white;
                    
                    &:hover {
                        background-color: #66b1ff;
                        border-color: #66b1ff;
                    }
                }
                
                &:nth-child(3) {
                    background-color: #909399;
                    border-color: #909399;
                    color: white;
                    
                    &:hover {
                        background-color: #a6a9ad;
                        border-color: #a6a9ad;
                    }
                }
            }
        }
    }
    
    .proxy-text {
        font-family: 'Courier New', monospace;
        color: #606266;
    }
    
    .time-text {
        color: #909399;
        font-size: 13px;
        
        &.update-time {
            color: #409EFF;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    .delay-text {
        font-family: 'Courier New', monospace;
        font-weight: 500;
        padding: 2px 6px;
        border-radius: 4px;
        
        &.delay-fast {
            color: #67C23A;
            background-color: rgba(103, 194, 58, 0.1);
        }
        
        &.delay-medium {
            color: #E6A23C;
            background-color: rgba(230, 162, 60, 0.1);
        }
        
        &.delay-slow {
            color: #F56C6C;
            background-color: rgba(245, 108, 108, 0.1);
        }
        
        &.delay-none {
            color: #909399;
            background-color: rgba(144, 147, 153, 0.1);
        }
    }
    
    :deep(.el-tag) {
        width: 80px;
        text-align: center;
        
        &.el-tag--success {
            background-color: rgba(103, 194, 58, 0.1);
            border-color: rgba(103, 194, 58, 0.2);
            color: #67C23A;
        }
        
        &.el-tag--danger {
            background-color: rgba(245, 108, 108, 0.1);
            border-color: rgba(245, 108, 108, 0.2);
            color: #F56C6C;
        }
    }
    
    :deep(.el-table) {
        border-radius: 8px;
        overflow: hidden;
        
        th {
            background-color: #f5f7fa !important;
        }
        
        td {
            padding: 12px 0;
        }
        
        tr:hover > td {
            background-color: #f5f7fa;
        }
        
        .expired-row {
            background-color: #f5f7fa;
            
            > td {
                background-color: #f5f7fa !important;
            }
            
            &:hover > td {
                background-color: #f5f7fa !important;
            }
            
            .el-button--danger {
                background-color: #f56c6c;
                border-color: #f56c6c;
                color: white;
                
                &:hover {
                    background-color: #f78989;
                    border-color: #f78989;
                }
                
                &:disabled {
                    background-color: #fab6b6;
                    border-color: #fab6b6;
                }
            }
        }
    }
    
    .highlight-row {
        > td {
            background-color: #f5f7fa !important;
        }
    }
    
    .order-cell {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .expired-tag {
            position: absolute;
            top: -2px;
            right: 2px;
            transform: scale(0.8);
            transform-origin: top right;
        }
    }
    
    .expired-text {
        color: #909399;
    }
}
</style>