<template>
  <div>
    <el-card class="box-card" shadow="hover">
      <div class="control-bar">
        <el-button-group>
          <el-button type="primary" icon="el-icon-back" size="small" @click="goBack">Back</el-button>
          <el-button type="primary" icon="el-icon-right" size="small" @click="goForward">Forward</el-button>
        </el-button-group>
        <el-button type="primary" icon="el-icon-refresh" size="small" @click="refresh">Refresh</el-button>
      </div>
      <div class="frame-container">
        <iframe ref="taskFrame" src="https://www.mentosproxy.com/flower/" frameborder="0" class="flower-frame"></iframe>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Flower',
  methods: {
    refresh() {
      const frame = this.$refs.taskFrame;
      frame.src = frame.src;
    },
    goBack() {
      const frame = this.$refs.taskFrame;
      frame.contentWindow.history.back();
    },
    goForward() {
      const frame = this.$refs.taskFrame;
      frame.contentWindow.history.forward();
    }
  }
}
</script>

<style scoped>
.box-card {
  margin: 20px;
}

.control-bar {
  padding: 8px;
  background-color: #f5f7fa;
  border-bottom: 1px solid #e4e7ed;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.frame-container {
  height: calc(100vh - 200px);
  position: relative;
}

.flower-frame {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
