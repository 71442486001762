<template>
    <div class="" v-loading="loading">
        <div class="layout">
            <header-com :index="0" :scrollTop="scrollTop"></header-com>
            <div class="fcokeprox">
                <div class="wrap">
                    <div class="fcokeimgs wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                        <div class="imgs">
                            <img src="@/assets/images/fico9.png" alt="">
                        </div>
                        <div class="chi"><img src="@/assets/images/fico7.png" alt=""></div>
                        <div class="fnbt animate__animated animate__slideOutDown animate__infinite" @click="scroll_pro">
                            <img src="@/assets/images/fico8.png" alt=""></div>
                        <div class="ftlt">
                            <swiper :options="swiperOption" ref="mySwiper" class="swipertab">
                                <swiper-slide v-for="(item, index) in collectionListData" :key="index">
                                    <div class="ftlt_li" @click="toProduct(item.id)"><i><span>{{
                                        item.collection_name
                                        }}</span></i></div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>

                    <div class="fpickthe" id="fpickthe">
                        <form action="" method="post">
                            <div class="fpitl wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1s">
                                <h3>Pick the best product for you！</h3>
                                <i></i>
                            </div>
                            <div class="list wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <ul>
                                    <li class="ftobor">
                                        <h2>Select the function you need:</h2>
                                        <div class="select">
                                            <dl>
                                                <dd v-for="(item, index) in labelListData" 
                                                    :key="index"
                                                    class="label-grid-item">
                                                    <label :class="item.choose ? 'active_label' : ''"
                                                           @click="chooseLable(item.id,index)">
                                                        {{ item.tag_name }}
                                                    </label>
                                                </dd>
                                            </dl>
                                        </div>
                                        <div class="jianto" v-if="chooseId !== ''"></div>
                                    </li>
                                    <li class="ftobor ftobor2 index_choose_type" v-if="chooseId !== ''">
                                        <img src="@/assets/images/fimg2.jpg" alt="" class="index_choose_type_bg"/>
                                        <div class="fmore" @click="chooseMoreBtn"><i>See more</i></div>
                                        <div class="fbest">
                                            <img src="@/assets/images/fico13.png" alt="">
                                        </div>
                                        <div class="fcoke_content">
                                            <div class="fcoke clearfix">
                                                <div class="ic"><img src="@/assets/images/fico15.png" alt=""></div>
                                                <h3>Coke proxy</h3>
                                            </div>
                                            <div class="del" style="width:520px;">
                                                <el-table :data="productListData" height="200px" :show-header="false">
                                                    <el-table-column property="product_name" label="Date"
                                                                     align="center">
                                                    </el-table-column>
                                                    <el-table-column property="product_desc"
                                                                     :show-overflow-tooltip='true' label="Points Earned"
                                                                     align="center">
                                                    </el-table-column>
                                                    <el-table-column property="lower_price" label="Points Earned"
                                                                     align="center">
                                                    </el-table-column>
                                                    <el-table-column align="center">
                                                        <template v-slot={row}>
                                                            <el-button
                                                                    style="font-family: 'Pang';background-color: #d6227a;color: #fff; border-radius: 10px;"
                                                                    size="mini"
                                                                    @click=toProduct(row.id,row.product_collections[0].id)>
                                                                order
                                                            </el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="fresetls wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
                                <ul class="clearfix">
                                    <li>
                                        <div class="frese clearfix">
                                            <dl class="clearfix">
                                                <dd class="freset"><a @click="resetLabel">Reset</a></dd>
                                                <dd class="fconfirm"><a @click="confirmLabel">Confirm</a></dd>
                                            </dl>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {swiper, swiperSlide} from "vue-awesome-swiper"
import "swiper/dist/css/swiper.css"
import headerCom from '@/components/header'
import {labelList, labelProduct, productList, getProductTag, tapList} from '@/api/index'

export default {
    name: 'Home',
    components: {
        swiper,
        swiperSlide,
        headerCom
    },
    data() {
        return {
            loading: false,
            swiperOption: {
                slidesPerView: 2, //横屏屏个数
                centeredSlides: true, //是否居中
                loop: true, //循环
                observer: true,
                observeParents: true,
                speed: 12000,
                autoplay: {
                    delay: 0,
                    stopOnLastSlide: false, //如果设置为true，当切换到最后一个slide时停止自动切换。（loop模式下无效）。
                    disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
                },
            },
            labelListData: [],//标签
            collectionListData: [],//系列
            scrollTop: 0,
            chooseId: '',
            selectId: [],
            productListData: [],
            proxyList: []
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper
        },
    },
    created() {
        // this.getProductList()
        this.getLabelList()
    },
    mounted() {
        new this.$wow.WOW().init()
        // //   this.getLabelList()
        //   let code = this.getUrlName('code')
        // 	if(!code&&!localStorage.getItem('token')){
        // 			 window.location.replace('https://discord.com/api/oauth2/authorize?client_id=983333457579094056&redirect_uri=http%3A%2F%2Fdashboard.mentostelecom.com&response_type=code&scope=identify')
        // 	}else if (code&&!localStorage.getItem('token')){
        // 		this.getUserByCodeFunc(code)
        // 	}else{

        // 	}
        // this.getLabelList()
        this.getProductCollection()
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        // 获取产品列表
        getProductList() {
            var that = this
            productList((res) => {
                this.productListData = res.data.data
                this.proxyList = res.data.data[0].product_collections
            }, (err) => {
                console.log(err)
            })
        },
        // 获取标签列表
        getLabelList() {
            labelList((res) => {
                this.labelListData = res.data.data.filter(item => item.tag_name.trim() !== '')
                this.labelListData.forEach((ele, index) => {
                    this.labelListData[index].choose = false
                })
            }, (err) => {
                console.log(err)
            })
        },
        // 获取标签产品系列
        getProductCollection() {
            var that = this
            tapList((res) => {
                this.collectionListData = res.data.data
                this.collectionListData.forEach((ele, index) => {
                    this.collectionListData[index].choose = false
                })
            }, (err) => {
                console.log(err)
            })
        },
        getUrlName(name) {
            /* eslint-disable */
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
        },
        //  getUserByCodeFunc(code){
        // 	 getUserByCode({code:code},(res) => {
        // 		localStorage.setItem('userInfo',JSON.stringify(res.result))
        // 		this.$store.commit('setUserInfo',res.result)
        // 		localStorage.setItem('token',res.result.token)
        // 		this.getLabelList()
        //     }, (err) => { console.log(err) })
        //  },
        // 选择标签
        chooseLable(name, index) {
            this.selectId.includes(name) ? this.selectId.splice(this.selectId.indexOf(name), 1) : this.selectId.push(name)
            this.labelListData[index].choose = !this.labelListData[index].choose
            this.$forceUpdate()
        },
        // 根据标签获取产品列表
        getLabelProduct(id) {
            getProductTag({id: id}, (res) => {
                this.productListData = res.result[0]
            }, (err) => {
                console.log(err)
            })
        },
        // 重置标签
        resetLabel() {
            this.labelListData.forEach(item => {
                item.choose = false
            })
            this.productListData = []
            this.chooseId = ""
            this.$forceUpdate()
        },
        // 选择标签
        confirmLabel() {
            // var data = ''
            // for (var i in this.labelListData) {
            // 	if (this.labelListData[i].choose) {
            // 		data += this.labelListData[i].id + ','
            // 	}
            // }
            // // 跳转product页面
            // this.toProduct(data)
            this.chooseId = 'name'
            this.loading = true
            if (this.selectId.length) {
                getProductTag({tags: this.selectId.join(',')}, (res) => {
                    this.loading = false
                    this.productListData = res.data.data
                }, (err) => {
                    console.log(err)
                })
            } else {
                this.$message.warning("Please select a product")
                this.loading = false
                this.productListData = []
                this.chooseId = ""
            }
        },
        chooseMoreBtn() {
            this.toProduct(this.chooseId)
        },
        // 跳转product
        toProduct(id, parId) {
            this.$router.push({path: '/product', query: {id, parId}})
        },
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
            this.scrollTop = scrollTop
        },
        scroll_pro() {
            document.querySelector("#fpickthe").scrollIntoView(true)
        }
    }
}
</script>
<style scoped>
.layout {
    background: url(../assets/images/fbj1.jpg) no-repeat center/cover;

}

.el-table .cell {
    white-space: nowrap !important;
}


.ftlt .ftlt_li i {
    position: relative;
    z-index: 5;
    display: inline-block;
    margin: 0;
}


.ftlt_li:hover i:before {
    position: absolute;
    z-index: 1;
    content: "";
    width: 410px;
    height: 93px;
    bottom: -26px;
    left: -10%;
    right: 0;
    margin: 0 auto;
    background: url(../assets/images/fico11-1.png) no-repeat left center;
}

.ftlt .ftlt_li:hover {
    transform: scale(1.2);
}

.select dl {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    padding: 20px;
}

.label-grid-item {
    text-align: center;
}

.label-grid-item label {
    display: block;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.label-grid-item label:hover {
    background-color: #f5f5f5;
}

.label-grid-item .active_label {
    background-color: #d6227a;
    color: white;
    border-color: #d6227a;
}

/* 添加表格内容样式 */
.el-table .cell {
    white-space: nowrap !important;
}

/* 如果需要表格宽度自适应内容 */
.del {
    width: 100% !important;
    max-width: 520px;
}

/* 确保表格行高合适 */
.el-table {
    line-height: 1.5;
}
</style>